import { useLocation } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import { GeneralContext } from "../contexts/GeneralContext";
import ProgressCircle from "../components/ProgressCircle";
import useUserDetails from "../hooks/useUserDetails";
import useConditionalNavigation from "../hooks/navigation/useConditionalNavigation";
import { useNavigate } from "react-router-dom";
import {doc, onSnapshot} from "firebase/firestore";
import {firebase_firestore} from "../../config/firebaseConfig";

export default function UnauthenticatedRouteGuard({ children }: any) {
    const { checkAccountStatuses, setRepId, setTrialDays } = useContext(GeneralContext);

    const conditionalNav = useConditionalNavigation();

    const location = useLocation(); // get location from useLocation hook
    const navigate = useNavigate();

    const {
        user,
        loadingUser: loading,
        loadingUserDetails: loadingUserMeta,
        userDetails: usermeta,
    } = useUserDetails();

    useEffect(() => {
        if (!user) {
            return;
        }

        const docRef = doc(firebase_firestore, `usermeta/${user?.uid}`);
        const unsubscribe = onSnapshot(docRef, (snapshot) => {
            const data = snapshot.data();
            if (data?.registerPhase) {
                navigate(`/${data.registerPhase}`);
            }
        });

        return () => {
            unsubscribe();
        };
    }, [navigate, user]);

    useEffect(() => {
        if (location) {
            const params = new URLSearchParams(location.search);
            const repId = params.get('repId');
            if (repId) {
                setRepId(repId);
            }
            const td = params.get('td');
            setTrialDays(td ? Number(td) : null);
        }
    }, [location, setRepId, setTrialDays]);


    useEffect(() => {
        if (user && !loading && usermeta) {
            checkAccountStatuses(usermeta);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usermeta]);

    if (loading || loadingUserMeta) {
        return <ProgressCircle />;
    }

    if (user && !loading && usermeta) {
        if (children.type && children.type.name === 'TraderAI') {
            return children;
        }

        if (conditionalNav.component) {
            return conditionalNav.component;
        }
    }

    return children;
}
