import React from 'react';
import { Box } from "@mui/material";

export default function Footer() {
    const currentYear = new Date().getFullYear(); // Fetch the current year dynamically

    return (
        <>
            <div style={{
                paddingTop: '1rem',
                marginTop: '1rem',
                color: 'white',
                background: 'black',
                position: 'relative',
                bottom: '0px',
                width: '100%',
                padding: '1rem 0rem',
                display: "flex",
                justifyContent: "space-between"
            }}>
                <Box
                    component={"img"}
                    style={{ marginLeft: '15px' }}
                />
                <Box style={{ marginRight: '1.2rem' }}>
                    <div style={{ textAlign: "end" }}>
                        © {currentYear} Green Chart LLC
                    </div>
                    <div style={{ textAlign: 'end', fontSize: '11px' }}>
                        <div>
                            help@greenchart.com
                        </div>
                        <div>
                            1.801.901.3356
                        </div>
                    </div>
                </Box>
            </div>
        </>
    );
}
