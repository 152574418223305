import React, {useEffect, ReactNode, useState} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { doc, onSnapshot } from "firebase/firestore";
import {
    firebase_auth,
    firebase_firestore,
} from "../../config/firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import ProgressCircle from "../components/ProgressCircle";
import {Box, CircularProgress, Stack} from "@mui/material";
import useSetPhase from "../hooks/registerationPhases/useSetPhase";

interface SignupPhaseGuardProps {
    children: ReactNode;
}

// The SignupPhaseGuard's purpose: redirect pages to the signup page until registration phases are complete.
export default function SignupPhaseGuard({ children }: SignupPhaseGuardProps) {
    const [user, loadingUser] = useAuthState(firebase_auth);

    // Track whether we’ve finished checking the user’s registration phase
    const [isCheckingSignupPhase, setIsCheckingSignupPhase] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();

    const setPhase = useSetPhase();

    useEffect(() => {
        if (loadingUser) {
            return;
        }

        if (!user) {
            setIsCheckingSignupPhase(false);
            return;
        }

        const docRef = doc(firebase_firestore, `usermeta/${user.uid}`);
        onSnapshot(docRef, async (snapshot) => {
            const usermeta = snapshot.data();

            // No user or no phases, let them continue to the page
            if (!usermeta || !usermeta.registerPhase) {
                setIsCheckingSignupPhase(false);
                return;
            }

            const emailVerificationStarted = 1735933722000; // Jan 3rd 2025
            const usermetaCreatedAt = user.metadata.creationTime;
            const createdAtNumber = new Date(usermetaCreatedAt ?? "").getTime();
            const isSignUpAfterDeadline = createdAtNumber >= emailVerificationStarted;

            if (usermeta.registerPhase === "verify-email" && user.emailVerified) {
                await setPhase('membership-level')
            }

            // If going through the process of purchasing a subscription, don't redirect
            if (location.pathname === '/subscription' || location.pathname === '/confirmation' || location.pathname === '/processing-order') {
                setIsCheckingSignupPhase(false);
                return;
            }

            // Redirect users to the signup page if they have not completed all phases
            if (usermeta.registerPhase && isSignUpAfterDeadline && location.pathname !== '/signup') {
                navigate(`/signup${location.search}`, { replace: true });
                setIsCheckingSignupPhase(false);
                return;
            }

            setIsCheckingSignupPhase(false);
        });
    }, [user, loadingUser, navigate, location.search, location.pathname, setPhase]);

    if (loadingUser || isCheckingSignupPhase) {
        const signupPage = location.pathname === '/signup';

        if (signupPage) {
            return <Box
                sx={{
                    backgroundColor: "#030a23",
                    minHeight: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Stack
                    spacing={3}
                    sx={{
                        minWidth: "360px",
                        minHeight: "400px",
                        justifyContent: "center",
                        alignContent: "center",
                        backgroundColor: "#030a23",
                    }}
                >
                    <Box style={{ display: "flex", justifyContent: "center" }}>
                        <CircularProgress color={"success"} size={"5rem"} />
                    </Box>
                </Stack>
            </Box>
        }
        return <ProgressCircle />
    }

    return <>{children}</>;
}