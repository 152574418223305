import React, { createContext, useState } from "react";
import { createTheme } from "@mui/material";
import { StatusesMapper } from "../../landing/helpers/statusesMapper";

const theme = createTheme({
    components: {
        MuiTypography: {
            variants: [
                {
                    props: {
                        variant: "h6" // Normal
                    },
                    style: {
                        fontSize: 16
                    }
                },
                {
                    props: {
                        variant: "h5" // Bolded normal
                    },
                    style: {
                        fontSize: 16,
                        fontWeight: 700,
                        color: "#008000"
                    }
                },
                {
                    props: {
                        variant: "h2" // Title
                    },
                    style: {
                        fontSize: 22,
                        fontWeight: 700
                    }
                }
            ]
        }
    }
});

type GeneralContextType = {
    preserveUrlParams: (searchParams: any, additionalParams?: { [key: string]: string }) => string;
    setHasChartingAccess: React.Dispatch<React.SetStateAction<boolean>>;
    setIsTrialMember: React.Dispatch<React.SetStateAction<boolean>>;
    setHasGreenChartTradingHouse: React.Dispatch<React.SetStateAction<boolean>>;
    setCart: React.Dispatch<React.SetStateAction<any>>;
    setTradingHouse: React.Dispatch<React.SetStateAction<string | null>>;
    cart: any;
    generalTheme: typeof theme;
    tradingHouse: string | null;
    hasChartingAccess: boolean;
    isTrialMember: boolean;
    hasGreenChartTradingHouse: boolean;
    checkAccountStatuses: (usermeta: any) => any;
    setHasCourse: React.Dispatch<React.SetStateAction<boolean>>;
    hasCourse: boolean;
    resetGeneralStates: () => void;
    hasStrategySuiteAccess: boolean | null;
    repId: string | null;
    setRepId: React.Dispatch<React.SetStateAction<string | null>>;
    trialDays: number | null;
    setTrialDays: React.Dispatch<React.SetStateAction<number | null>>;
    userDetails: any;
    setUserDetails: React.Dispatch<React.SetStateAction<any>>;
    userDetailsLoading: boolean;
    setUserDetailsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    userDetailsError: any;
    setUserDetailsError: React.Dispatch<React.SetStateAction<any>>;
    hasGTF: boolean;
    setHasGTF: React.Dispatch<React.SetStateAction<boolean>>;
    hasFreeGTF: boolean;
    setHasFreeGTF: React.Dispatch<React.SetStateAction<boolean>>;
};

function preserveUrlParams(searchParams: any, additionalParams?: { [key: string]: string }) {
    let items = "";

    for (const [key, value] of searchParams.entries()) {
        if (!items.length) {
            items += `?${key}=${value}`;
        } else {
            items += `&${key}=${value}`;
        }
    }

    if (additionalParams) {
        for (const [key, value] of Object.entries(additionalParams)) {
            if (!items.length) {
                items += `?${key}=${value}`;
            } else {
                items += `&${key}=${value}`;
            }
        }
    }

    return items;
}

export const GeneralContext = createContext<GeneralContextType>(undefined!);

export function GeneralProvider({ children }: any) {
    const [cart, setCart] = useState<any>({});
    const [tradingHouse, setTradingHouse] = useState<string | null>(null);
    const [hasChartingAccess, setHasChartingAccess] = useState<boolean>(false);
    const [hasStrategySuiteAccess, setHasStrategySuiteAccess] = useState<boolean | null>(null);
    const [hasCourse, setHasCourse] = useState<boolean>(false);
    const [isTrialMember, setIsTrialMember] = useState<boolean>(false);
    const [hasGreenChartTradingHouse, setHasGreenChartTradingHouse] = useState<boolean>(true);
    const [repId, setRepId] = useState<string | null>(null);
    const [trialDays, setTrialDays] = useState<number | null>(null);
    const [userDetails, setUserDetails] = useState<any>(null);
    const [userDetailsLoading, setUserDetailsLoading] = useState<boolean>(false);
    const [userDetailsError, setUserDetailsError] = useState<any>(null);
    const [hasGTF, setHasGTF] = useState<boolean>(false);
    const [hasFreeGTF, setHasFreeGTF] = useState<boolean>(false);

    const resetGeneralStates: () => void = () => {
        setCart({});
        setTradingHouse(null);
        setHasChartingAccess(false);
        setHasStrategySuiteAccess(null);
        setHasCourse(false);
        setIsTrialMember(false);
        setHasGreenChartTradingHouse(true);
        setRepId(null);
        setTrialDays(null);
        setUserDetails(null);
        setUserDetailsLoading(false);
        setUserDetailsError(null);
        setHasGTF(false);
        setHasFreeGTF(false);
    };


    function checkAccountStatuses(usermeta: any) {
        let _hasChartingAccess = hasChartingAccess;
        let _hasGreenChartTradingHouse = hasGreenChartTradingHouse;
        let _isTrialMember = isTrialMember;
        let hasPaidSS = false;

        if (usermeta && usermeta.tradingHouses) {
            _hasGreenChartTradingHouse = !!usermeta.tradingHouses.greenchart;
            setHasGreenChartTradingHouse(_hasGreenChartTradingHouse);

            const statuses = Object.values(usermeta.tradingHouses)
                .filter((houseStatus: any) => houseStatus.currentStatus)
                .map((houseStatus: any) => houseStatus.currentStatus.status);

            if (statuses.some(status => StatusesMapper.subscriptionStatuses.includes(status))) {
                _hasChartingAccess = true;
                setHasChartingAccess(_hasChartingAccess);
            } else if (statuses.some(status => StatusesMapper.needsActionStatuses.includes(status))) {
                _hasChartingAccess = false;
                setHasChartingAccess(_hasChartingAccess);

                _isTrialMember = statuses.some(status => StatusesMapper.trialStatuses.includes(status));
                setIsTrialMember(_isTrialMember);
            }

            // check if they have Strategy Suite access
            const hasAlertsProd = !!usermeta.products && !!usermeta.products.alerts;
            setHasStrategySuiteAccess(hasAlertsProd || usermeta.isAdmin);

            hasPaidSS = hasAlertsProd && usermeta.products.alerts.name !== "Starter";
        }

        if (usermeta && usermeta.products && usermeta.products.courses && usermeta.products.courses.length) {
            setHasCourse(true);
        } else if (usermeta && (!usermeta.products || !usermeta.products.courses || !usermeta.products.courses.length)) {
            setHasCourse(false);
        }

        // Check if the user has GTF access
        const hasGTF = !!usermeta.tradingHouses.gtf
            && StatusesMapper.gtfProductStatuses.includes(userDetails.tradingHouses.gtf.currentStatus.status);
        
        setHasGTF(hasGTF);

        return {
            hasPaidSS,
            hasChartingAccess: _hasChartingAccess,
            hasGreenChartTradingHouse: _hasGreenChartTradingHouse,
            isTrialMember: _isTrialMember,
            hasGTF
        };
    }

    return (
        <GeneralContext.Provider
            value={{
                cart,
                setCart,
                preserveUrlParams,
                generalTheme: theme,
                setTradingHouse,
                tradingHouse,
                hasChartingAccess,
                setHasChartingAccess,
                hasCourse,
                setHasCourse,
                hasStrategySuiteAccess,
                isTrialMember,
                setIsTrialMember,
                setHasGreenChartTradingHouse,
                hasGreenChartTradingHouse,
                checkAccountStatuses,
                resetGeneralStates,
                setRepId,
                repId,
                setTrialDays,
                trialDays,
                userDetails,
                setUserDetails,
                userDetailsLoading,
                setUserDetailsLoading,
                userDetailsError,
                setUserDetailsError,
                hasGTF,
                setHasGTF,
                hasFreeGTF,
                setHasFreeGTF
            }}
        >
            {children}
        </GeneralContext.Provider>
    );
}
