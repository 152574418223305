import React, {useState} from "react";
import {Box, Button, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import {useSignInWithEmailAndPassword} from "react-firebase-hooks/auth";
import {firebase_auth} from "../../../config/firebaseConfig";
import EmailTextField from "../../../common/components/EmailTextField";
import PasswordField from "../fields/PasswordField";
import RedirectExplanation from "./helpers/RedirectExplanation";
import logo from "../../../assets/stacked_logo.png";

interface LoginFormProps {
    onLoginSuccess: () => void;
    onSwitchToSignUp: () => void;
    onSwitchToForgotPassword: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onSwitchToSignUp, onSwitchToForgotPassword }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const px = isMobile ? 2 : 12

    const [
        signInWithEmailAndPassword,
        user,
        loading,
        error,
    ] = useSignInWithEmailAndPassword(firebase_auth);

    function loginUser() {
        void signInWithEmailAndPassword(email, password)
    }

    function errorMessage() {
        if (!loading && !user && error?.message) {
            return 'Oops! It appears that your username or password is incorrect. Please try again.'
        }
    }

    return (
        <Box
            sx={{
                backgroundColor: "#030a23",
                minHeight: "100vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "0px",
            }}
        >
            <Box
                sx={{
                    width: isMobile ? "90%" : "600px",
                    backgroundColor: "white",
                    borderRadius: "8px",
                    boxShadow: "none",
                    overflow: "visible",
                    padding: "16px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "16px",
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            width: "200px",
                            cursor: "pointer",
                        }}
                        src={logo}
                        onClick={() => window.open("https://www.greenchart.com", "_blank")}
                    />
                </Box>
                    <Stack
                        spacing={3}
                        sx={{
                            minWidth: "360px",
                            minHeight: "200px",
                            justifyContent: "center",
                        }}
                    >
                        <Box px={px} sx={{color:'#C62828'}}>{errorMessage()}</Box>
                        <RedirectExplanation px={px}/>
                        <Typography sx={{alignSelf: 'center', fontSize: '22px', fontWeight: 500}}>Sign in to get started</Typography>

                        <Box px={px}>
                            <EmailTextField
                                onChange={setEmail}
                                errorMessaging={false}
                            />
                        </Box>

                        <Box px={px}>
                            <PasswordField setPassword={setPassword} onSubmit={loginUser}/>
                        </Box>

                        <Box px={px}>
                            <Button
                                variant="contained"
                                onClick={() => loginUser()}
                                sx={{width: '100%'}}
                            >Login</Button>
                        </Box>

                        <Stack direction="row" sx={{alignSelf: 'center'}} pb={2}>
                            <Button onClick={onSwitchToSignUp}>
                                New user?
                            </Button>
                            <Button onClick={onSwitchToForgotPassword}>
                                Forgot password?
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Box>
    );
};

export default LoginForm;