import {createContext, useCallback, useState} from "react";

interface breadCrumbsHelperContextInterface {
    breadCrumbs: {name: string, location: string}[],
    clearCrumbs: () => void,
    pushCrumb: (name: string, location: string, clear?: boolean) => void,
    pushCrumbs: (crumbs: {name: string, location: string}[], clear?: boolean) => void,
    removeLastCrumb: () => void,
    removeCrumbsUpTo: (name: string) => void,
}

// @ts-ignore
export const BreadCrumbsHelperContext = createContext<breadCrumbsHelperContextInterface>({} as breadCrumbsHelperContextInterface);

export function BreadCrumbHelper({children}: any) {
    const [breadCrumbs, setBreadCrumbs] = useState<{name: string, location: string}[]>([])

    function getCrumbsCopy() {
        return [...breadCrumbs];
    }

    const clearCrumbs = useCallback(() => {
        setBreadCrumbs(() => []);
    }, []);

    function pushCrumb(name: string, location: string, clear?: boolean) {
        const tempBreadCrumbs = clear ? [] : getCrumbsCopy();
        const crumbInBreadLocation = checkIfCrumbInBread(name);

        if (crumbInBreadLocation !== -1) {
            removeCrumbsUpTo(name);
        } else {
            tempBreadCrumbs.push({name: name, location: location});
            setBreadCrumbs(tempBreadCrumbs);
        }
    }

    const pushCrumbs = useCallback((crumbs: { name: string; location: string }[], clear?: boolean) => {
        setBreadCrumbs(prevBreadCrumbs => {
            const tempBreadCrumbs = clear ? [] : [...prevBreadCrumbs];
            const lastCrumbName = crumbs[crumbs.length - 1].name;
            const crumbInBreadLocation = prevBreadCrumbs.findIndex(crumb => crumb?.name === lastCrumbName);

            if (crumbInBreadLocation !== -1) {
                tempBreadCrumbs.length = crumbInBreadLocation + 1;
            } else {
                crumbs.forEach(({ name, location }) => {
                    tempBreadCrumbs.push({ name, location });
                });
            }
            return tempBreadCrumbs;
        });
    }, []);

    function removeLastCrumb() {
        const tempBreadCrumbs = getCrumbsCopy();
        tempBreadCrumbs.pop();

        setBreadCrumbs(tempBreadCrumbs);
    }

    function removeCrumbsUpTo(name: string) {
        const tempBreadCrumbs = getCrumbsCopy();
        const crumbLocation = checkIfCrumbInBread(name);

        tempBreadCrumbs.length = crumbLocation + 1;
        setBreadCrumbs(tempBreadCrumbs);
    }

    function checkIfCrumbInBread(name: string): number {
        return breadCrumbs.findIndex((crumb: {name: string, location: string}) => {
            return crumb.name === name;
        });
    }

    // function getCurrentCrumbsName() {
    //     if (breadCrumbs.length === 0) {
    //         return null;
    //     }
    //
    //     return breadCrumbs[breadCrumbs.length - 1].name;
    // }

    return(
        <BreadCrumbsHelperContext.Provider
            value={{
                breadCrumbs: breadCrumbs,
                clearCrumbs: clearCrumbs,
                pushCrumb: pushCrumb,
                pushCrumbs: pushCrumbs,
                removeLastCrumb: removeLastCrumb,
                removeCrumbsUpTo: removeCrumbsUpTo,
        }}>
            {children}
        </BreadCrumbsHelperContext.Provider>
    )
}