import React, {useEffect, useState, createRef, useContext} from "react";
import {
    Box,
    Button,
    Checkbox,
    Link,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
    TextField, CircularProgress,
} from "@mui/material";
import FirstNameField from "../fields/FirstNameField";
import LastNameField from "../fields/LastNameField";
import EmailTextField from "../../../common/components/EmailTextField";
import PhoneField from "../fields/PhoneField";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { firebase_auth, firebase_functions } from "../../../config/firebaseConfig";
import { SignUpLocationMap } from "../../../common/helpers/SignUpLocationMap";
import { useLocation } from "react-router-dom";
import { signInWithCustomToken, sendEmailVerification, User } from "firebase/auth";
import { isValidPhoneNumber } from "react-phone-number-input";
import ReCAPTCHA from "react-google-recaptcha";
import { passwordStrength } from 'check-password-strength';
import {SnackbarHelperContext} from "../../../common/contexts/SnackbarHelperContext";
import logo from "../../../assets/stacked_logo.png";

interface SignUpFormProps {
    onSwitchToLogin: () => void;
    setErrorMessage: (arg0: any) => void;
    errorMessage: any;
}

const SignUpForm: React.FC<SignUpFormProps> = ({
                                                   onSwitchToLogin,
                                                   setErrorMessage,
                                                   errorMessage,
                                               }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const px = isMobile ? 2 : 12;
    const recaptchaRef = createRef<ReCAPTCHA>();
    const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;
    const location = useLocation();

    // State variables
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isFNValid, setIsFNValid] = useState("");
    const [isLNValid, setIsLNValid] = useState("");
    const [isPhoneValid, setIsPhoneValid] = useState("");
    const [agreed, setAgreed] = useState<boolean>(false);
    const [shouldProceed, setShouldProceed] = useState(false);
    const [welcomeText, setWelcomeText] = useState("Sign up to get started");
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [passwordError, setPasswordError] = useState<string>("");
    const [confirmPasswordError, setConfirmPasswordError] = useState<string>("");

    // UTM Parameters
    const [utmSource, setUtmSource] = useState<string | null>(null);
    const [utmMedium, setUtmMedium] = useState<string | null>(null);
    const [utmCampaign, setUtmCampaign] = useState<string | null>(null);
    const [utmTerm, setUtmTerm] = useState<string | null>(null);
    const [utmContent, setUtmContent] = useState<string | null>(null);

    const [registering, setRegistering] = useState(false);
    const {setSnackbarSettings} = useContext(SnackbarHelperContext);

    const [register] = useHttpsCallable(
        firebase_functions,
        "inAppRegistration"
    );
    const [validateRecaptcha] = useHttpsCallable(firebase_functions, "recaptcha");

    // Function to parse UTM parameters from the URL
    function getUtmParams() {
        if (!location) return;

        const params = new URLSearchParams(location.search);
        const baseSource = window.location.hostname;
        const sourceFromParams = params.get("utm_source");
        setUtmSource(sourceFromParams || baseSource);
        setUtmMedium(params.get("utm_medium"));
        setUtmCampaign(params.get("utm_campaign"));
        setUtmTerm(params.get("utm_term"));
        setUtmContent(params.get("utm_content"));

        const marketplacePage = params.get("display");
        if (!marketplacePage) return;

        if (marketplacePage === "membership") {
            setWelcomeText(`Sign up to get started with your free trial!`);
        } else if (marketplacePage === "learn") {
            setWelcomeText(`Sign up to get started with Currency Trading Unleashed!`);
        }
    }

    useEffect(() => {
        getUtmParams();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        if (password) {
            const strengthResult = passwordStrength(password);
            const { value } = strengthResult;

            if (value === "Too weak" || value === "Weak") {
                let requirements: string[] = [];

                if (password.length < 8) {
                    requirements.push("at least 8 characters long");
                }
                if (!/[A-Z]/.test(password)) {
                    requirements.push("an uppercase letter");
                }
                if (!/[a-z]/.test(password)) {
                    requirements.push("a lowercase letter");
                }
                if (!/[0-9]/.test(password)) {
                    requirements.push("a number");
                }
                if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
                    requirements.push("a special character");
                }

                setPasswordError(
                    `Password is too weak. It needs ${requirements.join(", ")}.`
                );
            } else {
                setPasswordError("");
            }
        } else {
            setPasswordError("");
        }

        if (confirmPassword) {
            if (confirmPassword !== password) {
                setConfirmPasswordError("Passwords do not match.");
            } else {
                setConfirmPasswordError("");
            }
        } else {
            setConfirmPasswordError("");
        }
    }, [password, confirmPassword]);

    useEffect(() => {
        const isPasswordLengthValid = password.length >= 8;
        const doPasswordsMatch = password === confirmPassword;
        const passwordStrengthValue = passwordStrength(password).value;
        const isPasswordStrong = !["Too weak", "Weak"].includes(passwordStrengthValue);

        setShouldProceed(
            isEmailValid &&
            agreed &&
            firstName.trim().length > 0 &&
            lastName.trim().length > 0 &&
            isValidPhoneNumber(phoneNumber) &&
            isPasswordLengthValid &&
            doPasswordsMatch &&
            isPasswordStrong
        );
    }, [
        isEmailValid,
        agreed,
        firstName,
        lastName,
        phoneNumber,
        password,
        confirmPassword,
    ]);

    useEffect(() => {
        const nameRegex = /^[a-zA-Z]*$/;

        if (firstName && !nameRegex.test(firstName)) {
            setIsFNValid("Only alphabetical letters are allowed.");
        } else {
            setIsFNValid("");
        }

        if (lastName && !nameRegex.test(lastName)) {
            setIsLNValid("Only alphabetical letters are allowed.");
        } else {
            setIsLNValid("");
        }
    }, [firstName, lastName]);

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            if (phoneNumber && !isValidPhoneNumber(phoneNumber)) {
                setIsPhoneValid("Invalid phone number.");
            } else {
                setIsPhoneValid("");
            }
        }, 1000);

        return () => clearTimeout(debounceTimer);
    }, [phoneNumber]);

    async function registerUser() {
            setRegistering(true);
            setErrorMessage(null);

            const strength = passwordStrength(password).value;
            if (strength === "Too weak" || strength === "Weak") {
                setPasswordError("Password is too weak. Please use a stronger password.");
                return;
            }

            if (password !== confirmPassword) {
                setConfirmPasswordError("Passwords do not match.");
                return;
            }

            setPasswordError("");
            setConfirmPasswordError("");

            const token = await recaptchaRef.current!.executeAsync()

            const response = await validateRecaptcha({
                event: {
                    token: token,
                    expectedAction: 'USER_ACTION',
                    siteKey: recaptchaKey,
                },
            });

        try {
            if (response && response.data) {
                const page = SignUpLocationMap[getCurrentSubdirectory()];
                const registerResponse = await register({
                    email,
                    firstName,
                    lastName,
                    phoneNumber,
                    password,
                    page,
                    utmSource,
                    utmMedium,
                    utmCampaign,
                    utmTerm,
                    utmContent,
                    tokenRequested: true,
                });

                await checkRegisterData(registerResponse);
            } else {
                setSnackbarSettings({severity: 'error', message: 'reCAPTCHA verification failed. Please try again.'})
            }
        } catch (error: any) {
            console.error("Error during registration:", error);
            setSnackbarSettings({severity: 'error', message: 'An unexpected error occurred. Please try again later.'})
            setRegistering(false);
        } finally {
            setRegistering(false);
        }
    }

    async function checkRegisterData(response: any) {
        if (response && response.data) {
            const { status, token } = response.data;
            const success = status === 204;
            const fail = status === 409;

            if (success) {
                const urlParams = new URLSearchParams(window.location.search);
                const userCredential = await signInWithCustomToken(firebase_auth, token);
                const user: User = userCredential.user;
                await sendEmailVerification(user);

                if (urlParams.has("display")) {
                    try {
                        setRegistering(false);
                    } catch (error: any) {
                        console.error("Error during sign-in or sending verification email:", error);
                        setSnackbarSettings({severity: 'error', message: 'Failed to send verification email. Please try logging in.'})
                        setRegistering(false);
                    }
                } else {
                    setRegistering(false);
                }
            } else if (fail) {
                setSnackbarSettings({severity: 'error', message: `An account with the email ${email} is already in use.`})
                setRegistering(false);
            } else {
                setSnackbarSettings({severity: 'error', message: 'Something went wrong, please contact support if this issue continues to persist.'})
                setRegistering(false);
            }
        } else {
            setSnackbarSettings({severity: 'error', message: 'Invalid response from server. Please try again later.'    })
            setRegistering(false);
        }
    }

    function getCurrentSubdirectory(): string {
        const path = window.location.pathname;
        const pathParts = path.split("/");
        const nonEmptyPathParts = pathParts.filter((part) => part);
        return nonEmptyPathParts.length > 0 ? nonEmptyPathParts[0] : "";
    }

    return (
        <>
        <Box
            sx={{
                backgroundColor: "#030a23",
                minHeight: "100vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "32px",
            }}
        >
            <Box
                sx={{
                    width: isMobile ? "90%" : "600px",
                    backgroundColor: "white",
                    borderRadius: "8px",
                    boxShadow: "none",
                    overflow: "visible",
                    padding: "16px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "16px",
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            width: "200px",
                            cursor: "pointer",
                        }}
                        src={logo}
                        onClick={() => window.open("https://www.greenchart.com", "_blank")}
                    />

                </Box>
                {(registering ? <Stack
                        spacing={3}
                        sx={{
                            minWidth: "360px",
                            minHeight: "400px",
                            justifyContent: "center",
                            alignContent: "center",
                        }}
                    >
                        <Box style={{display: "flex", justifyContent: "center"}}>
                            <CircularProgress color={"success"} size={"5rem"}/>
                        </Box>
                    </Stack> :
                    <Stack
                        spacing={3}
                            sx={{
                                minWidth: "360px",
                                minHeight: "200px",
                                justifyContent: "center",
                            }}
                        >
                            <Box px={px} sx={{ textAlign: "center" }}>
                                <Typography sx={{ alignSelf: "center", fontSize: "22px", fontWeight: 500 }}>
                                    {welcomeText}
                                </Typography>
                            </Box>
                            <Box px={px}>
                                <EmailTextField setIsEmailValid={setIsEmailValid} onChange={setEmail} required />
                            </Box>
                            <Box px={px}>
                                <FirstNameField
                                    setFirstName={setFirstName}
                                    required
                                    error={!!isFNValid}
                                    helperText={isFNValid}
                                />
                            </Box>
                            <Box px={px}>
                                <LastNameField
                                    setLastName={setLastName}
                                    required
                                    error={!!isLNValid}
                                    helperText={isLNValid}
                                />
                            </Box>
                            <Box px={px}>
                                <PhoneField
                                    setPhoneNumber={setPhoneNumber}
                                    phoneNumber={phoneNumber}
                                    error={!!isPhoneValid}
                                    helperText={isPhoneValid}
                                />
                            </Box>
                            {/* Password Fields */}
                            <Box px={px} mt={2}>
                                <TextField
                                    label="Password"
                                    type="password"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    error={!!passwordError}
                                    helperText={passwordError}
                                />
                            </Box>
                            <Box px={px} mt={2}>
                                <TextField
                                    label="Confirm Password"
                                    type="password"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    error={!!confirmPasswordError}
                                    helperText={confirmPasswordError}
                                />
                            </Box>
                            <Box px={px} mt={2}>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        color: "rgb(167, 167, 167)",
                                        transition: "color 0.3s ease-in-out",
                                        "&:hover": {
                                            color: "black",
                                        },
                                    }}
                                >
                                    By signing up with GreenChart I will receive email newsletters, be enrolled in
                                    webinar trainings, and receive email and SMS (text) notifications concerning my
                                    account and special offers. I have also read and agree to the{" "}
                                    <Link
                                        target="_blank"
                                        underline="none"
                                        href="https://www.greenchart.com/terms-of-use"
                                        rel="noopener noreferrer"
                                    >
                                        Terms of Use
                                    </Link>{" "}
                                    of this website.
                                </Typography>
                                <Box
                                    pt={1}
                                    pb={1}
                                    sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                >
                                    <Checkbox
                                        sx={{ padding: "0px", paddingRight: "9px" }}
                                        onChange={() => setAgreed(!agreed)}
                                        checked={agreed}
                                    />
                                    I agree
                                </Box>
                            </Box>
                            <Box px={px} mt={2}>
                                <Button
                                    sx={{ width: "100%" }}
                                    variant="contained"
                                    onClick={registerUser}
                                    disabled={!shouldProceed}
                                >
                                    Sign up
                                </Button>
                            </Box>
                            <Box px={px} sx={{ color: "#C62828" }}>
                                {errorMessage}
                            </Box>
                            <Stack direction="row" sx={{ alignSelf: "center" }} pb={2}>
                                <Button onClick={onSwitchToLogin}>Already have an account?</Button>
                            </Stack>
                        </Stack> )}
                </Box>
        </Box>
    <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={recaptchaKey || ""}
        grecaptcha={{ enterprise: true }}
    />
    </>
    );
};

export default SignUpForm;
