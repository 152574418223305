import {TextField} from "@mui/material";

export default function LastNameField(props: {
    setLastName: (n: string) => void;
    required?: boolean;
    error?: boolean;
    helperText?: string;
}) {
    const { setLastName, required, error, helperText } = props;

    return (
        <TextField
            required={required}
            label="Last Name"
            variant="outlined"
            onChange={(e) => setLastName(e.target.value)}
            inputProps={{
                style: {
                    padding: "16.5px 14px",
                },
            }}
            sx={{
                width: "100%",
            }}
            error={error}
            helperText={helperText}
        />
    );
}