import {useState, useCallback, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import useFindOneTimePaymentItems from "./useFindOneTimePaymentItems";
import useFindSubscriptionItems from "./useFindSubscriptionItems";
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {firebase_functions} from "../../config/firebaseConfig";
import {GeneralContext} from "../../common/contexts/GeneralContext";
import {SnackbarHelperContext} from "../../common/contexts/SnackbarHelperContext";

// Assume createIntent, getInvoiceItems, findOneTimePaymentItems, and findSubscriptionItems
// are defined and imported from elsewhere

function useStripeClientSecret(coupon: any, clearInvoices: boolean) {
    const [stripeClientSecret, setStripeClientSecret] = useState<string | null>(null);
    const [oneTimePurchaseItems, setOneTimePurchaseItems] = useState<any[]>([]);
    const [showType, setShowType] = useState<string>('');
    const [itemsData, setItemsData] = useState<any[]>([]);
    const [errorLoadingSubscription, setErrorLoadingSubscription] = useState<boolean>(false);
    const {setSnackbarSettings} = useContext(SnackbarHelperContext);

    const { repId, trialDays } = useContext(GeneralContext);

    const findOneTimePaymentItems = useFindOneTimePaymentItems();
    const findSubscriptionItems = useFindSubscriptionItems();

    const navigate = useNavigate();

    const [createIntent] = useHttpsCallable(
        firebase_functions,
        'createStripeGreenChartSubscriptionIntent'
    );

    const [getInvoiceItems] = useHttpsCallable(
        firebase_functions,
        'getInvoiceItems'
    );

    const getStripeClientSecret = useCallback(async (priceIds: any[]) => {
        try {
            const response = await createIntent({
                products: priceIds,
                tradingHouse: 'greenchart',
                coupon,
                clearItems: clearInvoices,
                repId,
                trialDays,
            }) as any;

            const invoiceItems = await getInvoiceItems() as any;

            if (response && response.data && response.data.subscription) {
                const clientSecret = response.data.subscription.clientSecret;
                setStripeClientSecret(clientSecret);

                if (invoiceItems) {
                    setOneTimePurchaseItems(invoiceItems.data.invoiceItems);
                }

                // Status handling logic
                if (response.data.status === 204) {
                    setShowType('payment');
                    setItemsData(response.data.subscription.items);
                } else if (response.data.status === 205) {
                    setShowType('payment');
                    setOneTimePurchaseItems(findOneTimePaymentItems(response.data.subscription.items));
                    setItemsData(findSubscriptionItems(response.data.subscription.items));
                } else if (response.data.status === 206) {
                    setShowType('card');
                    setItemsData(response.data.subscription.items);
                }
            } else {
                setSnackbarSettings({severity: 'error', message: `Encountered an issue, please try again.`})
                setErrorLoadingSubscription(true);
                navigate(-1);
            }
        } catch (error) {
            setSnackbarSettings({severity: 'error', message: error as string})
            // Handle any errors here
            setErrorLoadingSubscription(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coupon, clearInvoices, navigate]);

    return {
        getStripeClientSecret,
        setStripeClientSecret,
        stripeClientSecret,
        oneTimePurchaseItems,
        setOneTimePurchaseItems,
        showType,
        itemsData,
        errorLoadingSubscription,
    };
}

export default useStripeClientSecret;
