import React from "react";
import useUserDetails from "../hooks/useUserDetails";
import {Navigate, useLocation} from "react-router-dom";
import Box from "@mui/material/Box";
import {CircularProgress, Stack} from "@mui/material";

export default function SignupRouteGuard({ children }: any) {
    const {user, loadingUser: loading, loadingUserDetails: loadingUserMeta, userDetails: usermeta} = useUserDetails();
    const location = useLocation();

    if (loading || loadingUserMeta) {
        return (
            <Box
                sx={{
                    backgroundColor: "#030a23",
                    minHeight: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Stack
                    spacing={3}
                    sx={{
                        minWidth: "360px",
                        minHeight: "400px",
                        justifyContent: "center",
                        alignContent: "center",
                    }}
                >
                    <Box style={{ display: "flex", justifyContent: "center" }}>
                        <CircularProgress color={"success"} size={"5rem"} />
                    </Box>
                </Stack>
            </Box>
        );

    }

    const emailVerificationStarted = 1735933722000; // Jan 3rd 2025

    if (user && usermeta) {
        const usermetaCreatedAt = user.metadata.creationTime;
        const createdAtNumber = new Date(usermetaCreatedAt ?? "").getTime();
        const isSignedUpBeforeDeadline = createdAtNumber < emailVerificationStarted;

        if ((user.emailVerified && !usermeta.registerPhase) || isSignedUpBeforeDeadline) {
            if (location.pathname !== '/dashboard/strategy-suite') {
                return <Navigate to='/dashboard/strategy-suite' replace />;
            }
        }
    }

    return children
};
