import useGenerateCurrentProductsList from "./useGenerateCurrentProductsList";
import {useMarket} from "../../../../../common/contexts/MarketContext";
import createPriceArray from "../../../../pages/app/marketplace/helpers/createPriceArray";
import {useLocation, useSearchParams} from "react-router-dom";
import useFindProductId from "./useFindProductId";
import {checkPriceIsTrial} from "../../../../pages/app/marketplace/helpers/checkItemIsTrial";
import {AlertProductMap} from "../../../../../common/helpers/AlertProductMap";
import MarketStatusMap from "../../../../../common/helpers/MarketStatusMap";


export default function useDetermineMarketStatus(): (priceId: string) => string {
    const {state} = useMarket();
    const [searchParams] = useSearchParams();
    const prices = createPriceArray(state, searchParams);
    const generateCurrentProducts = useGenerateCurrentProductsList();
    const currentProducts = generateCurrentProducts(prices);
    const findProductId = useFindProductId();
    const location = useLocation();

    return (priceId: string) => {
        const productId = findProductId(priceId);
        const price = state.products[productId][priceId];
        const currentProductPriceId = currentProducts[productId];

        const onSignupPage = location.pathname === "/signup";

        if (onSignupPage) {
            return MarketStatusMap.select;
        }

        if (price.price === 0 && !currentProductPriceId) {
            return MarketStatusMap.currentPrice;
        }
        else if (!currentProductPriceId || !priceId) {
            return MarketStatusMap.purchase;
        }

        const currentProductPrice = parseInt(state.products[productId][currentProductPriceId].price);
        const priceIdPrice = parseInt(price.price);

        const isTrial = checkPriceIsTrial(price) && AlertProductMap[process.env.REACT_APP_ENVIRONMENT as string] === price.productId

        if (currentProductPrice > priceIdPrice) {
            return MarketStatusMap.downgrade;
        } else if (currentProductPrice < priceIdPrice) {
            return MarketStatusMap.upgrade;
        }
        else if (isTrial) {
            return MarketStatusMap.currentPriceTrial;
        } else {
            return MarketStatusMap.currentPrice;
        }
    }
}