import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import logo from "../../../../assets/stacked_logo.png";
import ModalContent from "../ModalContent";

interface Props {
    continueUrl?: string;
}

const SetPassword: React.FC<Props> = ({ continueUrl }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Box
            sx={{
                minHeight: "100vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#030a23",
            }}
        >
            <Box
                sx={{
                    width: isMobile ? "90%" : "600px",
                    backgroundColor: "white",
                    borderRadius: "8px",
                    boxShadow: "none",
                    padding: "16px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "16px",
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            width: "200px",
                            cursor: "pointer",
                        }}
                        src={logo}
                        onClick={() => window.open("https://www.greenchart.com", "_blank")}
                    />
                </Box>
                <ModalContent initialState={'setPassword'} />
            </Box>
        </Box>
    );
};

export default SetPassword;
