import React, {useContext, useEffect} from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import ProgressCircle from "../../../../common/components/ProgressCircle";
import ProductsDisplay from "../../../../dashboard/components/marketplace/renderProductPrices/ProductsDisplay";
import { useMarket } from "../../../../common/contexts/MarketContext";
import createPriceArray from "../../../../dashboard/pages/app/marketplace/helpers/createPriceArray";
import useUserDetails from "../../../../common/hooks/useUserDetails";
import Header from "../../../../common/components/Header";
import CouponInput from "../../../../dashboard/components/marketplace/inputs/CouponInput";
import AnnualToggle from "../../../../dashboard/components/marketplace/AnnualToggle";
import BreadCrumbsNavigation from "../../../../common/components/BreadCrumbsNavigation";
import {BreadCrumbsHelperContext} from "../../../../common/contexts/BreadCrumbsHelperContext";

export default function MembershipLevelSelect() {
    const { userDetails } = useUserDetails();
    const { state, getProducts, executingGetActiveProducts } = useMarket();
    const { productDetails, products } = state;
    const {pushCrumbs, clearCrumbs} = useContext(BreadCrumbsHelperContext);

    const [searchParams, setSearchParams] = useSearchParams(); // Correct destructuring

    useEffect(() => {
        clearCrumbs();
        pushCrumbs([{name: 'Membership Select', location: '/signup'}]);
    }, [clearCrumbs, pushCrumbs])

    useEffect(() => {
        // Ensure `display=membership` is present in the query params
        if (!searchParams.has("display") || searchParams.get("display") !== "membership") {
            const newParams = new URLSearchParams(searchParams.toString());
            newParams.set("display", "membership");
            setSearchParams(newParams, { replace: true }); // Use replace to avoid adding history entries
        }
    }, [searchParams, setSearchParams]);

    useEffect(() => {
        if (!productDetails || !products) void getProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDetails, productDetails, products]);

    function organizePricesIntoPricePairs(prices: any) {
        const pricePairs = [];
        const addedPrices: string[] = [];

        for (const price of prices) {
            const priceMatch = prices.find((potentialPriceMatch: any) =>
                (price.metadata.monthlyEquivalent
                    ? price.metadata.monthlyEquivalent
                    : price.metadata.annualEquivalent) === potentialPriceMatch.priceId
            );
            const priceNotAddedYet =
                !addedPrices.includes(price.priceId) &&
                !addedPrices.includes(priceMatch?.priceId);

            if (priceNotAddedYet && !priceMatch) {
                pricePairs.push({
                    monthly: price,
                });
            } else if (priceMatch && priceNotAddedYet) {
                addedPrices.push(price.priceId);
                addedPrices.push(priceMatch.priceId);
                pricePairs.push({
                    annual: price.metadata.monthlyEquivalent ? price : priceMatch,
                    monthly: price.metadata.annualEquivalent ? price : priceMatch,
                });
            }
        }
        return pricePairs;
    }

    const prices = createPriceArray(state, searchParams);
    const pricePairs = organizePricesIntoPricePairs(prices);

    return (
        <>
            <Header logoOnly={true} />
            <Box
                pt={4}
                pl={4}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                <BreadCrumbsNavigation key={3}/>
            </Box>
            <Box pt={2} pl={2} pr={2}>
                {(!state || executingGetActiveProducts) ? (
                    <ProgressCircle />
                ) : (
                    <Grid container>
                        {/* Left Side: Controls */}
                        <Grid item xs={12} md={2}>
                            <Box display="flex" flexDirection="column" gap={2}>
                                <CouponInput
                                    onValidCoupon={(coupon) =>
                                        console.log(`Coupon ${coupon} is valid`)
                                    }
                                />
                                <AnnualToggle products={pricePairs} />
                            </Box>
                        </Grid>

                        {/* Right Side: Product Display */}
                        <Grid item xs={12} md={10}>
                            <ProductsDisplay prices={prices} pricePairs={pricePairs} />
                        </Grid>
                    </Grid>
                )}
            </Box>
        </>
    );
}
