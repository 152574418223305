import React, {useState} from 'react';
import {Stack, Button, Box, useTheme, useMediaQuery, Typography} from "@mui/material";
import {useSendPasswordResetEmail} from "react-firebase-hooks/auth";
import {firebase_auth} from "../../../config/firebaseConfig";
import {useNavigate} from "react-router-dom";
import '../../../App.css'
import EnterEmail from "../../components/forgotPassword/EnterEmail";
import logo from "../../../assets/stacked_logo.png";

interface ForgottenPasswordProps {
    onSwitchToLogin?: () => void;
}

const ForgottenPasswordForm: React.FC<ForgottenPasswordProps> = ({ onSwitchToLogin }) => {
    const [emailSent, setEmailSent] = useState(false);
    const [email, setEmail] = useState('');
    const [sendPasswordResetEmail] = useSendPasswordResetEmail(firebase_auth);
    const navigate = useNavigate();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const px = isMobile ? 2 : 12

    const navigateToLogin = () => {
        navigate("/login");
    };

    return (

        <Box
            sx={{
                backgroundColor: "#030a23",
                minHeight: "100vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "32px",
            }}
        >
            <Box
                sx={{
                    width: isMobile ? "90%" : "600px",
                    backgroundColor: "white",
                    borderRadius: "8px",
                    boxShadow: "none",
                    overflow: "visible",
                    padding: "16px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "16px",
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            width: "200px",
                            cursor: "pointer",
                        }}
                        src={logo}
                        onClick={() => window.open("https://www.greenchart.com", "_blank")}
                    />
                </Box>


                    <Stack
        spacing={3}
        sx={{
            minWidth: "360px",
            minHeight: "200px",
            justifyContent: "center",
        }}
    >
        {!emailSent && <>
            <Box
                style={{textAlign: "center"}}
                px={px}
            >
                <Stack

                    spacing={3}
                    sx={{
                        backgroundColor: '#fffff'
                    }}
                >
                    <EnterEmail
                        email={email}
                        sendPasswordResetEmail={sendPasswordResetEmail}
                        setEmail={setEmail}
                        setEmailSent={setEmailSent}
                    />
                </Stack>
            </Box>
        </>}
        {emailSent && <>
            <Box
                maxWidth="sm"
                style={{textAlign: "center"}}
                px={px}
            >
                <Stack
                    spacing={3}
                    sx={{
                        backgroundColor: '#fffff'
                    }}
                >
                    <Box></Box>
                    <Box>
                        <Typography sx={{alignSelf: 'center', fontSize: '22px', fontWeight: 500}}>Check Your Inbox</Typography>
                        <Typography style={{ color: "gray", fontWeight: 400, fontSize: '18px' }}>
                            Click the link in the email to reset your password
                        </Typography>
                    </Box>

                    <Button
                        onClick={onSwitchToLogin || navigateToLogin}
                        sx={{color: 'white'}}
                        variant="contained"
                    >Go to login</Button>
                </Stack>
            </Box>
        </>}
        <Stack direction="row" sx={{alignSelf: 'center'}} pb={2}>
            <Button onClick={onSwitchToLogin}>
                Back
            </Button>
        </Stack>
    </Stack>
                </Box>
            </Box>
                    );
}

export default ForgottenPasswordForm;
