import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import React from "react";
import "../../components/styles/CustomInput.css";

const CustomInput = React.forwardRef((props, ref) => {
    return (
        <input
            {...props} // @ts-ignore
            ref={ref}
            style={{ padding: "16.5px 14px", width: "100%" }}
        />
    );
});

export default function PhoneField(props: {
    setPhoneNumber: (n: string) => void;
    phoneNumber: string;
    error?: boolean;
    helperText?: string;
}) {
    const { phoneNumber, setPhoneNumber, error, helperText } = props;

    return (
        <div>
            <PhoneInput
                className={"CustomInput.css"}
                value={phoneNumber}
                limitMaxLength={true}
                defaultCountry={"US"}
                placeholder={"Mobile Phone (Required)"}
                onChange={(number) => {
                    setPhoneNumber(number || "");
                }}
                inputComponent={CustomInput}
            />
            {error && <div style={{ color: "red", marginTop: "8px", fontSize: "12px", paddingLeft: "14px" }}>{helperText}</div>}
        </div>
    );
}
