import React, {useContext, useEffect, useState} from 'react';
import {
    AppBar,
    Box,
    Button,
    Container,
    Dialog,
    Divider,
    Drawer,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    ThemeProvider,
    Toolbar,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {signOut} from 'firebase/auth';
import {firebase_auth} from "../../config/firebaseConfig";
import {GeneralContext} from "../contexts/GeneralContext";

import logo from "../../assets/member_center_gc_logo.png"
import {TradingHouseMapper} from "../helpers/TradingHouseMap";
import {buttonColor, HeaderTheme} from "../themes/HeaderThemes";
import List from "@mui/material/List";
import DialogTitle from "@mui/material/DialogTitle";
import {useMarket} from "../contexts/MarketContext";
import {useExternalRedirect} from "../hooks/useExternalRedirect";
import useTradingHouses from "../hooks/useTradingHouse";
import useUserDetails from "../hooks/useUserDetails";
import useActionManager from "../hooks/useGetActionParams";

// Icons
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MenuIcon from '@mui/icons-material/Menu';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LogoutIcon from '@mui/icons-material/Logout';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import StorefrontIcon from '@mui/icons-material/Storefront';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import BuildIcon from '@mui/icons-material/Build';
import SchoolIcon from '@mui/icons-material/School';
import EventIcon from '@mui/icons-material/Event';
import {useHasFreeGTF} from "../hooks/useHasGTF";

interface HeaderProps {
    callback?: (...args: any[]) => void;
    logoOnly?: boolean;
}

const Header: React.FC<HeaderProps> = ({callback, logoOnly}) => {

    const logout = () => {
        resetGeneralStates();
        resetMarketContext();
        void signOut(firebase_auth);
    };

    const goToPage = (setting: string) => {
        navigate(`${setting.toLowerCase()}`)
    }

    const { user, userDetails } = useUserDetails();
    const { resetGeneralStates } = useContext(GeneralContext);
    const { resetMarketContext } = useMarket();
    const location = useLocation()
    const redirectToExternalSite = useExternalRedirect();

    const tradingHouses = useTradingHouses();
    const hasFreeGTF = useHasFreeGTF();

    const userNavigationMap = [
        {
            name: 'Admin',
            function: () => {goToPage('/admin')},
            color: "red",
            isVisible: userDetails && userDetails.isAdmin,
            icon: <AdminPanelSettingsIcon/>
        },
        {
            name: 'Account',
            function: () => {goToPage('/dashboard/account')},
            isVisible: true,
            icon: <ManageAccountsIcon/>
        },
        {
            name: 'Logout',
            function: logout,
            isVisible: true,
            icon: <LogoutIcon/>
        }
    ];
    const servicesMap = [
        {
            name: 'Charts',
            function: (event: any) => {processAppClick(event)},
            isVisible: true,
            icon: <CandlestickChartIcon/>
        },
        {
            name: 'Marketplace',
            function: () => {goToPage('/dashboard/marketplace?display=membership')},
            isVisible: user,
            icon: <StorefrontIcon/>
        },
        {
            name: 'DayTradeAi',
            function: () => {goToPage('/ai')},
            isVisible: true,
            icon: <SupportAgentIcon/>
        },
        {
            name: 'Learning',
            redirectLink: 'https://learn.greenchart.com',
            function: () => { redirectToExternalSite('https://learn.greenchart.com', location.pathname) },
            isVisible: !!user, //hasCourse || userDetails?.isAdmin
            icon: <SchoolIcon/>
        },
        {
            name: 'Econ Calendar',
            function: () => {goToPage('/dashboard/economic-calendar')},
            isVisible: true,
            icon: <EventIcon/>
        },
        {
            name: 'Strategy Suite',
            function: (event: any) => {processStrategySuiteClick(event)},
            isVisible: true,
            icon: <BuildIcon/>
        },
    ]

    const navigate = useNavigate();

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [tradingHouseMenuOpen, setTradingHouseMenuOpen] = useState<boolean>(false);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [action, setAction] = useState<string | null>(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    };
    const handleClose = () => {
        setTradingHouseMenuOpen(false);
        setAnchorEl(null);
        setAction(null);
    };

    const { getAction, clearAction } = useActionManager();
    useEffect(() => {
        const _action = getAction();
        setAction(_action);

        const availableTradingHouses = getAvailableTradingHouses();

        if (_action === 'charts') {
            if (availableTradingHouses && availableTradingHouses.length) {
                directToSite();
                clearAction();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tradingHouses]);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setIsDrawerOpen(open);
    };

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const processAppClick = (event: any) => {
        // if (!hasChartingAccess && !isTrialMember) {
        //     return navigate('marketplace?display=membership')
        // }

        directToSite();
        handleClick(event);
    }

    const processStrategySuiteClick = (event: any) => {
        goToPage('/dashboard/strategy-suite');
        handleClick(event);
    }

    // opens menu & directs to site
    function directToSite(tradingHouseName?: string) {

        const availableTradingHouses = getAvailableTradingHouses()
        const route = userDetails?.isBetaTester ? 'betaURL' : 'greenchart';
        const moreThanOneTradingHouseActive = availableTradingHouses && availableTradingHouses.length > 1;


        if (availableTradingHouses.length === 0) {
            // do nothing
        } else if (tradingHouseName) {
            const redirectLink = availableTradingHouses.find((th: any) => th.name === tradingHouseName).routes[route];
            redirectToExternalSite(redirectLink, location.pathname);
        } else if (moreThanOneTradingHouseActive) {
            // open menu
            if (isMobile) {
                setIsDrawerOpen(false);
            }
            setTradingHouseMenuOpen(true);
        } else {
            // there is only one trading house
            const redirectLink = availableTradingHouses[0].routes[route];
            redirectToExternalSite(redirectLink, location.pathname);
        }
    }

    // We no longer need to limit what people are seeing, keeping this if we change that
    function displayHeaderLinks() {
        // if greenchart is one of the trading houses, display the normal header
        // for (const tradingHouse of tradingHouses) {
        //     if (tradingHouse.name === 'greenchart') {
        //         return renderMemberHeader();
        //     }
        // }

        // if (isTrialMember) {
        //     return renderTrialHeader();
        // }

        return renderMemberHeader();
    }

    function renderMemberHeader() {
        if (logoOnly) {
            return null;
        }

        return isMobile ? null : (
            servicesMap.map((service: any) => {
                if (service.isVisible) {
                    return (
                        <Button
                            key={service.name}
                            onClick={service.function}
                            id={`${service.name}Option`}
                        >
                            {service.name}
                        </Button>
                    )
                }
                return null;
            })
        )
    }

    function openLoginDialog() {
        if (callback) {
            callback('login');
        }
    }

    const getAvailableTradingHouses = () => {
        const filterTradingHouses = (tradingHouses: {isAdmin: boolean, name: string}[]) => {
            return tradingHouses.filter((tradingHouse: any) => (userDetails?.isAdmin || hasFreeGTF || tradingHouse.name !== 'greenchart'));
        }

        if (!tradingHouses) return [];
        if (tradingHouses.length >= 2) {
            return filterTradingHouses(tradingHouses);
        }

        return tradingHouses;
    }

    // on the header when clicked
    function renderChartsDialog() {


        if (isMobile || action) {
            return (
                <Dialog
                    open={tradingHouseMenuOpen}
                    onClose={handleClose}
                >
                    <DialogTitle>{"Select a Trading House"}</DialogTitle>
                    <List>
                        {getAvailableTradingHouses().map(tradingHouse => (
                            <ListItem button onClick={() => directToSite(tradingHouse.name)} key={tradingHouse.name}>
                                <ListItemText primary={TradingHouseMapper[tradingHouse.name]} />
                            </ListItem>
                        ))}
                    </List>
                </Dialog>
            )
        }
    }

    function renderChartOptions() {
        const defaultAnchor = document.getElementById('ChartsOption');
        const anchor = anchorEl || defaultAnchor;

        if (!isMobile && !action) {
            return (
                <Menu
                    open={tradingHouseMenuOpen}
                    onClose={handleClose}
                    anchorEl={anchor}>
                    {tradingHouses.map(tradingHouse => {
                        return (
                            <MenuItem
                                key={tradingHouse.name}
                                onClick={() => {directToSite(tradingHouse.name)}}
                            >{TradingHouseMapper[tradingHouse.name]}</MenuItem>)
                    })}
                </Menu>
            );
        }
    }

    // function renderTrialHeader() {
    //     return (
    //         <>
    //             <Button
    //                 key={'Charts'}
    //                 onClick={processAppClick}
    //             >
    //                 {'Charts'}
    //             </Button>
    //         </>
    //     )
    // }

    function renderAccountMenuIcon() {
        if (logoOnly) {
            return null;
        }

        if (!user) {
            return (
                <Button
                    variant={'contained'}
                    sx={{
                        backgroundColor: 'rgb(0, 161, 255)',
                        borderRadius: '50px',
                        padding: '4px 22px',
                        '&:hover': {
                            backgroundColor: '#18BB0E',
                            boxShadow: 'none',
                        },
                    }}
                    onClick={openLoginDialog}
                >Log in</Button>
            )
        }

        return ( user && !isMobile &&
            <Box sx={{ flexGrow: 0 }}>
                {/*@ts-ignore*/}
                <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <ManageAccountsIcon fontSize={"inherit"} sx={{color: buttonColor}}/>
                    </IconButton>
                </Tooltip>
                <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >
                    {userNavigationMap.map((setting) => {
                        if (setting.isVisible) {
                            return <MenuItem key={setting.name} onClick={setting.function}>
                                <Typography textAlign="center"
                                            sx={{color: setting.color}}>{setting.name}</Typography>
                            </MenuItem>
                        }
                        return null
                    })}
                </Menu>
            </Box>
        )
    }

    return (
        <>
            {renderChartOptions()}
            {renderChartsDialog()}
            <AppBar
                position="static"
                sx={{ bgcolor: "#0d0d0d"}}
            >
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Box
                            height={'40px'}
                            component={"img"}
                            src={logo}
                        />
                        <ThemeProvider theme={HeaderTheme}>
                            <Box sx={{ flexGrow: 1, pl: 4 }}>
                                { displayHeaderLinks() }
                            </Box>
                        </ThemeProvider>

                        { isMobile && user &&
                            <IconButton
                                edge="end"
                                color="inherit"
                                aria-label="menu"
                                onClick={toggleDrawer(true)}
                            >
                                <MenuIcon />
                            </IconButton>
                        }

                        {renderAccountMenuIcon()}
                    </Toolbar>
                </Container>
            </AppBar>
            <Drawer
                anchor={'right'}
                open={isDrawerOpen}
                onClose={toggleDrawer(false)}
            >
                <Box
                    sx={{ width: 250 }}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <List>
                        {servicesMap.map((service) => {
                            if (service.isVisible) {
                                return (
                                    <ListItem button key={service.name} onClick={service.function}>
                                        <ListItemIcon>
                                            {/*// @ts-ignore*/}
                                            {service.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={service.name} />
                                    </ListItem>
                                );
                            }
                            return null;
                        })}
                        <Divider/>
                        {userNavigationMap.map((setting) => {
                            if (setting.isVisible) {
                                return (
                                    <ListItem button key={setting.name} onClick={setting.function}>
                                        <ListItemIcon>
                                            {/*// @ts-ignore*/}
                                            {setting.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={setting.name} />
                                    </ListItem>
                                );
                            }
                            return null;
                        })}
                    </List>
                </Box>
            </Drawer>
        </>
    )
}

export default Header;
