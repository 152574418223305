import React, {useEffect} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {firebase_auth} from "../../../config/firebaseConfig";
import useTitle from "../../../common/hooks/useTitle";
import {useSearchParams} from "react-router-dom";
import Box from "@mui/material/Box";


const iframeStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    border: 'none',
};

function StrategySuite() {

    useTitle('Strategy Suite');
    const [idToken, setIdToken] = React.useState<string | null>(null);
    const [user] = useAuthState(firebase_auth);
    const [searchParams, setSearchParams] = useSearchParams();
    const [linkId, setLinkId] = React.useState<string | null>(null);

    useEffect(() => {
        if (user) {
            user.getIdToken().then((idToken) => {
                setIdToken(idToken)
            })
        }
    }, [user]);

    useEffect(() => {
        const pid = searchParams.get('pid');
        if (pid) {
            // linkId = pid;
            setLinkId(pid);
            searchParams.delete('pid');
            setSearchParams(searchParams);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams])

    return (
        <Box sx={{ position: 'absolute', top: {xs: 56, md: 64}, left: 0, bottom: 0, width: '100%', height: {xs: 'calc(100% - 56px)', md: 'calc(100% - 64px)'}, overflowY: 'hidden' }}>
            <iframe
                title="Pattern Builder"
                allow="clipboard-write"
                src={`${process.env.REACT_APP_PATTERN_BUILDER_URL}?idToken=${idToken}${linkId ? `&pid=${linkId}` : ''}`}
                style={{ ...iframeStyle}}
            />
        </Box>
    )
}

export default StrategySuite
