import React, {createContext, useContext, useReducer, ReactNode, useState} from 'react';
import useUserDetails from "../hooks/useUserDetails";
import {StatusesMapper} from "../../landing/helpers/statusesMapper";
import {convertToDate} from "../../landing/helpers/convertUnixDate";
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {firebase_functions} from "../../config/firebaseConfig";
import {GeneralContext} from "./GeneralContext";

interface StripeCoupon {
    id: string;
    object: string;
    active: boolean;
    code: string;
    coupon: {
        applies_to: any;
        id: string;
        object: string;
        amount_off: number | null;
        created: number;
        currency: string | null;
        duration: string;
        duration_in_months: number | null;
        livemode: boolean;
        max_redemptions: number | null;
        metadata: Record<string, unknown>;
        name: string;
        percent_off: number;
        redeem_by: number | null;
        times_redeemed: number;
        valid: boolean;
    };
    created: number;
    customer: string;
    expires_at: number;
    livemode: boolean;
    max_redemptions: number;
    metadata: Record<string, unknown>;
    restrictions: {
        first_time_transaction: boolean;
        minimum_amount: number | null;
        minimum_amount_currency: string | null;
    };
}

interface MarketState {
    coupon: StripeCoupon | null;
    cart: any[]; // You might want to replace any with a more specific type
    prorationDate: string | null;
    products: any; // You might want to replace any with a more specific type
    productDetails: any; // You might want to replace any with a more specific type
    annualSelected: boolean;
}

interface SetCouponAction {
    type: 'SET_COUPON';
    payload: StripeCoupon | null;
}

interface AddToCartAction {
    type: 'ADD_TO_CART';
    payload: any; // You might want to replace any with a more specific type
}

interface SetProrationDateAction {
    type: 'SET_PRORATION_DATE';
    payload: string | null;
}

interface SetProductsAction {
    type: 'SET_PRODUCTS';
    payload: any; // You might want to replace any with a more specific type
}

interface SetProductDetailsAction {
    type: 'SET_PRODUCT_DETAILS';
    payload: any; // You might want to replace any with a more specific type
}

interface SetAnnualSelectedAction {
    type: 'SET_ANNUAL_SELECTED';
    payload: boolean;
}

interface resetMarketContext {
    type: 'RESET_MARKET_CONTEXT';
    payload: any;
}

type MarketAction =
    SetCouponAction |
    AddToCartAction |
    SetProrationDateAction |
    SetProductsAction |
    SetProductDetailsAction |
    SetAnnualSelectedAction |
    resetMarketContext;

interface MarketContextType {
    state: MarketState;
    dispatch: React.Dispatch<MarketAction>;
    getProductPrice: (productId: string, priceId: string) => any;
    checkCouponAppliesTo: (productId: any) => boolean;
    applyCouponToPrice: (priceStr: number) => number;
    resetMarketContext: () => void;
    getProducts: () => any;
    createStripeGreenChartSubscriptionIntent: (data: any) => any;
    upgradeLoading: boolean;
    downgradeSubscription: (data: any) => any;
    downgradeLoading: boolean;
    addInvoiceItems: (data: any) => any;
    addInvoiceItemsLoading: boolean;
    getActiveProducts: (tradingHouses: any) => void;
    billingDateYear: string | undefined;
    billingDateMonth: string | undefined;
    executingGetActiveProducts: boolean;
}

const MarketContext = createContext<MarketContextType | undefined>(undefined);

const marketReducer = (state: MarketState, action: MarketAction): MarketState => {
    switch (action.type) {
        case 'SET_COUPON':
            return { ...state, coupon: action.payload };
        case 'ADD_TO_CART':
            return { ...state, cart: [...state.cart, action.payload] };
        case 'SET_PRORATION_DATE':
            return { ...state, prorationDate: action.payload };
        case 'SET_PRODUCTS':
            return { ...state, products: action.payload };
        case 'SET_PRODUCT_DETAILS':
            return { ...state, productDetails: action.payload };
        case 'SET_ANNUAL_SELECTED':
            return { ...state, annualSelected: action.payload };
        default:
            throw new Error('Unknown action');
    }
};

interface MarketProviderProps {
    children: ReactNode;
}

export const MarketProvider: React.FC<MarketProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(marketReducer, {
        coupon: null,
        cart: [],
        prorationDate: null,
        products: null,
        productDetails: null,
        annualSelected: false,
    });

    const {userDetails} = useUserDetails();

    const [getActiveProducts, executingGetActiveProducts] = useHttpsCallable(
        firebase_functions,
        'getActiveProducts'
    );

    const [createStripeGreenChartSubscriptionIntent, upgradeLoading] = useHttpsCallable(
        firebase_functions,
        'createStripeGreenChartSubscriptionIntent'
    );

    const [downgradeSubscription, downgradeLoading] = useHttpsCallable(
        firebase_functions,
        'downgradeGreenChartSubscription'
    );

    const [addInvoiceItems, addInvoiceItemsLoading] = useHttpsCallable(
        firebase_functions,
        'addInvoiceItems'
    );

    const [billingDateYear, setBillingDateYear] = useState<string | undefined>();
    const [billingDateMonth, setBillingDateMonth] = useState<string | undefined>();

    const { trialDays } = useContext(GeneralContext);

    const getProducts = async () =>  {
        const tradingHousesToRequest = getTradingHousesToRequest(userDetails?.tradingHouses || [])
        if (!tradingHousesToRequest.length) return;
        const showProduct = window.location.search.includes('showProduct');

        let trialDaysFromParam = trialDays;
        // check for trial days in the URL as well as context
        if (!trialDays) {
            const params = new URLSearchParams(window.location.search);
            const trialDaysParam = params.get('td');

            const trialDaysParsed = trialDaysParam ? parseInt(trialDaysParam) : 0;
            if (!isNaN(trialDaysParsed) && trialDaysParsed < 30 && trialDaysParsed > 1) {
                trialDaysFromParam = trialDaysParsed;
            }
        }
        console.log('trailDays', trialDaysFromParam);

        await getActiveProducts({tradingHouses: tradingHousesToRequest, showProduct, trialDays: trialDaysFromParam}).then((res: any) => {
            if (!res) return;

            dispatch({ type: 'SET_PRORATION_DATE', payload: res.data.prorationDate });
            dispatch({ type: 'SET_PRODUCTS', payload: res.data.products });
            dispatch({ type: 'SET_PRODUCT_DETAILS', payload: res.data.productDetails });

            if (res.data.billingDateYear) {
                dispatch({ type: 'SET_ANNUAL_SELECTED', payload: true });
            }

            // @ts-ignore
            setBillingDateYear(convertToDate(res.data.billingDateYear));
            setBillingDateMonth(convertToDate(res.data.billingDateMonth));
        });
    }

    function getTradingHousesToRequest(tradingHouses: any) {
        const tradingHousesToRequest: string[] = [];

        for (const tradingHouse of Object.keys(tradingHouses)) {
            const isGTFMemberAndCancelled = tradingHouse === 'gtf' && StatusesMapper.needsActionStatuses.includes(tradingHouses[tradingHouse].currentStatus.status);

            if (isGTFMemberAndCancelled) {
                tradingHousesToRequest.push(tradingHouse);
            } else {
                tradingHousesToRequest.push(tradingHouse);
            }
        }

        return tradingHousesToRequest;
    }

    const getProductPrice = (productId: string, priceId: string) => {
        const product = state.products[productId];
        if (product) {
            return product[priceId];
        }
        return null;
    }

    const checkCouponAppliesTo = (productId: any) => {
        if (!state.coupon || !state.coupon.coupon) return false;

        const {applies_to} = state.coupon.coupon;

        if (!applies_to) { // CASE: no list of applies_to set, can work with any product
            return true;
        }

        return state.coupon.coupon.applies_to.products.includes(productId);
    }

    const applyCouponToPrice = (price: number): number => {
        if (!state.coupon || !state.coupon.coupon.valid || !state.coupon.active) {
            // The coupon is not valid or not active
            return price;
        }

        if (state.coupon.coupon.percent_off != null) {
            // The coupon provides a percentage discount
            price = price * (1 - (state.coupon.coupon.percent_off / 100));
        } else if (state.coupon.coupon.amount_off != null) {
            // The coupon provides a fixed amount discount
            price = price - state.coupon.coupon.amount_off;
            price = price > 0 ? price : 0; // Don't allow the price to go negative
        }
        return price;
    }

    const resetMarketContext = () => {
        dispatch({type: 'SET_COUPON', payload: null});
        dispatch({type: 'SET_PRORATION_DATE', payload: null});
        dispatch({type: 'SET_PRODUCTS', payload: null});
        dispatch({type: 'SET_PRODUCT_DETAILS', payload: null});
        dispatch({type: 'SET_ANNUAL_SELECTED', payload: false});
    }

    return (
        <MarketContext.Provider value={{
            state,
            dispatch,
            getProductPrice,
            checkCouponAppliesTo,
            applyCouponToPrice,
            resetMarketContext,
            getProducts,
            getActiveProducts,
            billingDateYear,
            billingDateMonth,
            executingGetActiveProducts,
            createStripeGreenChartSubscriptionIntent,
            upgradeLoading,
            downgradeSubscription,
            downgradeLoading,
            addInvoiceItems,
            addInvoiceItemsLoading
        }}>
            {children}
        </MarketContext.Provider>
    );
};


// @ts-ignore
export const useMarket = () => {
    const context = useContext(MarketContext);

    if (!context) {
        throw new Error('useMarket must be used within a MarketProvider');
    }

    return context as MarketContextType;
};