import React from 'react';
import ModalContent from "../ModalContent";

interface ForgottenPasswordProps {
    onSwitchToLogin?: () => void;
}

const ForgottenPassword: React.FC<ForgottenPasswordProps> = () => {
    return (<ModalContent
        initialState="forgotPassword"
    />);
};

export default ForgottenPassword;
