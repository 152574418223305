import React, { useState, useEffect } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useAuthState } from "react-firebase-hooks/auth";
import ModalContent from "../ModalContent";
import Header from "../../../../common/components/Header";
import { firebase_auth } from "../../../../config/firebaseConfig";
import { useSearchParams } from "react-router-dom";
import useTitle from "../../../../common/hooks/useTitle";

const iframeStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    border: 'none',
};

type pagesOptions = 'signUp' | 'login' | 'emailVerification' | 'forgotPassword';

export default function TraderAI() {
    useTitle('DayTradeAI');
    const [user, loading] = useAuthState(firebase_auth);
    const hasDialogShown = localStorage.getItem('hasDialogShown') === 'true';
    const [showDialog, setShowDialog] = useState(hasDialogShown && !user && !loading);
    const [blurIframe, setBlurIframe] = useState(hasDialogShown && !user && !loading);
    const [state, setState] = useState<pagesOptions | undefined>(undefined);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [idToken, setIdToken] = useState<string | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [linkId, setLinkId] = useState<string | null>(null);

    useEffect(() => {
        if (user) {
            user.getIdToken().then((idToken) => {
                setIdToken(idToken)
            })
        }
    }, [user]);

    useEffect(() => {
        const pid = searchParams.get('pid');
        if (pid) {
            setLinkId(pid);
            searchParams.delete('pid');
            setSearchParams(searchParams);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    useEffect(() => {
        if (user && !loading) {
            setBlurIframe(false);
            setShowDialog(false);
        } else {
            setShowDialog(hasDialogShown && !user && !loading);
            setBlurIframe(hasDialogShown && !user && !loading);

            if (!hasDialogShown) {
                const timer = setTimeout(() => {
                    localStorage.setItem('hasDialogShown', 'true');
                    setShowDialog(true);
                    setBlurIframe(true);
                }, 150000); // 2 minutes and 30 seconds

                return () => clearTimeout(timer);
            }
        }
    }, [hasDialogShown, user, loading]);

    const iframeSrc = `${process.env.REACT_APP_TRADER_AI_URL}${idToken ? `?idToken=${idToken}` : ''}${linkId ? `&pid=${linkId}` : ''}`;

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                <Header callback={(page: string) => {
                    setShowDialog(true);
                    setState(page as pagesOptions);
                }}/>
                <Box sx={{ flexGrow: 1, position: 'relative', overflow: 'hidden' }}>
                    <iframe
                        title="TraderAI"
                        src={iframeSrc}
                        style={{ ...iframeStyle, filter: blurIframe ? 'blur(5px)' : 'none', position: 'absolute', top: 0, left: 0, bottom: 0 }}
                    />
                </Box>
                <Dialog
                    open={showDialog}
                    onClose={() => setShowDialog(true)}
                    disableEscapeKeyDown // Disable closing the dialog using the Escape key
                    fullScreen={isMobile}
                >
                    <DialogTitle sx={{ alignSelf: "center" }}>
                        <Typography component="div" sx={{ fontSize: '24px', fontWeight: 700 }}>
                            Continue your{" "}
                            <Box component="span" color="#18BB0E" fontStyle="italic">
                                FREE
                            </Box>{" "}
                            access
                        </Typography>
                    </DialogTitle>
                    <DialogContent
                        sx={{
                            padding: 0,
                            width: isMobile ? "auto" : "600px",
                        }}
                    >
                        <ModalContent
                            closeModal={() => setShowDialog(false)}
                            initialState={state}/>
                    </DialogContent>
                </Dialog>
            </Box>
            {/*<Footer/>*/}
        </>
    );
}
