import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Stack } from "@mui/material";
import ModalContent from "../ModalContent";
import { firebase_auth, firebase_firestore } from "../../../../config/firebaseConfig";
import { doc, onSnapshot } from "firebase/firestore";
import { useSearchParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import useSetPhase from "../../../../common/hooks/registerationPhases/useSetPhase";

export default function SignUp() {
    const [searchParams] = useSearchParams();

    const oobCode = searchParams.get("oobCode");

    const [contentState, setContentState] = useState<any>(null);
    const [user, loadingUser] = useAuthState(firebase_auth);

    const setPhase = useSetPhase();

    useEffect(() => {
        const mode = searchParams.get("mode");
        if (mode === "resetPassword") {
            setContentState("setPassword");
            return;
        }

        if (user) {
            const userDocRef = doc(firebase_firestore, `usermeta/${user.uid}`);

            const unsubscribe = onSnapshot(
                userDocRef,
                async (userDocSnapshot) => {
                    if (userDocSnapshot.exists()) {
                        const userDocData = userDocSnapshot.data();

                        if (userDocData.registerPhase) {
                            setContentState(userDocData.registerPhase);
                        } else {
                            setContentState("signUp");
                        }
                    } else {
                        console.warn("User document does not exist.");
                        setContentState("signUp");
                    }
                },
                (error) => {
                    console.error("Error listening to user phase:", error);
                    setContentState("signUp");
                }
            );

            return () => unsubscribe();
        } else {
            // If there's no authenticated user, set based on oobCode
            setContentState(!oobCode ? "signUp" : "verify-email");
        }
    }, [user, oobCode, setPhase, searchParams]);

    if (
        loadingUser ||
        contentState === null ||
        (user && contentState === "signUp")
    ) {
        return (
            <Box
                sx={{
                    backgroundColor: "#030a23",
                    minHeight: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Stack
                    spacing={3}
                    sx={{
                        minWidth: "360px",
                        minHeight: "400px",
                        justifyContent: "center",
                        alignContent: "center",
                        backgroundColor: "#030a23",
                    }}
                >
                    <Box style={{ display: "flex", justifyContent: "center" }}>
                        <CircularProgress color={"success"} size={"5rem"} />
                    </Box>
                </Stack>
            </Box>
        );
    }

    return <ModalContent initialState={contentState} />;
}