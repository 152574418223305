import useDetermineMarketStatus from "./useDetermineMarketStatus";
import useUserDetails from "../../../../../common/hooks/useUserDetails";
import {useLocation, useNavigate} from "react-router-dom";
import {useContext, useState} from "react";
import {BreadCrumbsHelperContext} from "../../../../../common/contexts/BreadCrumbsHelperContext";
import {GeneralContext} from "../../../../../common/contexts/GeneralContext";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {collection} from "firebase/firestore";
import {firebase_firestore} from "../../../../../config/firebaseConfig";
import useGetItemPrice from "./useGetItemPrice";
import {useDialog} from "../../../../../common/contexts/DialogContext";
import useDetermineUpgradeText from "./useDetermineUpgradeText";
import MarketStatusMap from "../../../../../common/helpers/MarketStatusMap";
import useDetermineDowngradeText from "./useDetermineDowngradeText";
import {StarterPriceMap} from "../../../../../common/helpers/StarterProductMap";
import useSetPhase from "../../../../../common/hooks/registerationPhases/useSetPhase";

export default function useAddItemToCart() {
    const { setOpenDialog, openUpgradeDialog, openDowngradeDialog } = useDialog();
    const getItemPrice = useGetItemPrice();
    const location = useLocation();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [, setCCDialogOpen] = useState(false);
    const {cart, setCart} = useContext(GeneralContext);
    const {userDetails, user} = useUserDetails();
    const navigate = useNavigate();
    const {pushCrumbs} = useContext(BreadCrumbsHelperContext);
    const [subscriptionsObject] = useCollectionData(collection(firebase_firestore, `usermeta/${user?.uid}/subscriptions`));
    const checkoutImmediately = true;
    const quantity = '1';
    const ownsSS = checkUserOwnsProduct('prod_MYMLx3TRAb3gkm');
    const hasCreditCard = true;
    const determineActionType = useDetermineMarketStatus();
    const determineUpgradeText = useDetermineUpgradeText();
    const determineDowngradeText = useDetermineDowngradeText();

    const setPhase = useSetPhase();

    function checkStarterSelectException(newCart: any) {
        const isItemStarter = newCart[StarterPriceMap[process.env.REACT_APP_ENVIRONMENT as string]];
        return !!isItemStarter;
    }

    function checkUserOwnsProduct(productId: string, subscriptionsObject?: any) {
        return subscriptionsObject?.some((subscription: { products: { prodId: string; }[]; }) =>
            subscription.products.some((product: { prodId: string; }) => product.prodId === productId)
        ) || false ;
    }

    function checkout(pushCrumbs: any, navigate: any) {
        const isSelect = location.pathname === '/membership-level';
        const destination = isSelect ? '/subscription?prev=member-level' : '/subscription';

        pushCrumbs([{name: 'Order Details', location: '/subscription'}]);
        navigate(destination);
    }

    return async (pricePair: any) => {
        const purchasingEventItem = pricePair?.productId === 'prod_PLPhkXM6jpr2na'; // hardcoded event item id
        if (!ownsSS && purchasingEventItem) {
            setOpenDialog('event');
            return;
        }

        if (!pricePair) {
            return null;
        }

        const itemPrice = getItemPrice(pricePair);

        //TODO REMOVE AFTER FRIDAY
        const newCart = {...cart};
        newCart[pricePair.priceId] = {};

        if (quantity) {
            newCart[pricePair.priceId].quantity = quantity;
        }

        if (pricePair.freeTrial) {
            newCart[pricePair.priceId].freeTrial = pricePair.freeTrial;
        }

        newCart[pricePair.priceId].productId = pricePair.productId;
        newCart[pricePair.priceId].type = pricePair.type;
        setCart(newCart);

        // Remove all other ids from the cart then go to checkout page
        if (checkoutImmediately) {
            Object.keys(newCart).forEach(key => {
                if (key !== pricePair.priceId) {
                    delete newCart[key];
                }
            })

            // TODO: Check if there is no stripe id && subscription on the user object
            const listOfTradingHousesEstablished = [];
            // @ts-ignore
            for (const subObject of subscriptionsObject) {
                listOfTradingHousesEstablished.push(subObject.tradingHouse);
            }

            const noSubscriptionOnAccount = !listOfTradingHousesEstablished.includes('greenchart') && !listOfTradingHousesEstablished.includes('greenchartYear');
            if (!hasCreditCard && !noSubscriptionOnAccount) {
                setCCDialogOpen(true);
                return;
            }

            if (noSubscriptionOnAccount) {
                const starterSelected = checkStarterSelectException(newCart);
                if (starterSelected) {
                    await setPhase();
                    const destination = '/dashboard/strategy-suite';
                    navigate(destination, { replace: true });
                } else {
                    checkout(pushCrumbs, navigate); // this component can only be created when creating a new subscription, our solution puts many items into one subscription
                }
            } else if (userDetails && userDetails.stripeCustomerId) {
                const actionType = determineActionType(pricePair.priceId);
                if (actionType === MarketStatusMap.currentPrice) {
                    // do nothing
                } else if (actionType === MarketStatusMap.downgrade) {
                    openDowngradeDialog(itemPrice, determineDowngradeText(itemPrice, pricePair.priceId));
                } else if (actionType === MarketStatusMap.upgrade || actionType === MarketStatusMap.purchase) {
                    openUpgradeDialog(itemPrice, determineUpgradeText(itemPrice));
                }
            }
        }
    }
}
