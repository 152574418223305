import React, { useEffect, useState } from 'react';
import { firebase_auth, firebase_firestore } from "../../../config/firebaseConfig";
import {Box, Container, Stack, Typography} from "@mui/material";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "../../components/CheckoutForm";
import CheckoutItems from "../../components/CheckoutItems";
import {priceMapper} from "../../helpers/priceMapper";
import {useAuthState} from "react-firebase-hooks/auth";
import {doc} from "firebase/firestore";
import {useDocumentDataOnce} from "react-firebase-hooks/firestore";
import Header from "../../../common/components/Header";
import BreadCrumbsNavigation from "../../../common/components/BreadCrumbsNavigation";
import ErrorIcon from '@mui/icons-material/Error';
import ProgressCircle from "../../../common/components/ProgressCircle";
import {StripePublicKeyMap} from "../../../common/helpers/StripePublicKeyMap";
import {useMarket} from "../../../common/contexts/MarketContext";
import UserDetailsForm, {userDetailsFormValues} from "../../../common/components/UserDetailsForm";
import Footer from "../../../common/components/Footer";
import {StatusesMapper} from "../../helpers/statusesMapper";

import useItemsFromCart from "../../hooks/useItemsFromCart";
import useItemsFromUrl from "../../hooks/useItemsFromUrl";
import useAddRequiredItems from "../../hooks/useAddRequiredItems";
import useSubmitUserDetails from "../../hooks/useSubmitUserDetails";
import useProcessOneTimePurchaseItems from "../../hooks/useProcessOneTimePurchaseItems";
import useCreateStripeSubscription from "../../hooks/useCreateStripeSubscription";
import useStripeClientSecret from "../../hooks/useGetStripeClientSecret";
import {useLocation} from "react-router-dom";

let stripePromise: any;
if (process.env && process.env.REACT_APP_ENVIRONMENT) {
    stripePromise = loadStripe(StripePublicKeyMap[process.env.REACT_APP_ENVIRONMENT]);
}

export default function GreenChartSubscription() {
    const [loadingSubscription, setLoadingSubscription] = useState(false);
    const [errorProcessingPayment, setErrorProcessingPayment] = useState('');
    const [initializingPage, setInitializingPage] = useState(true);
    const [pendingSubmit, setPendingSubmit] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [userInfoValidated, setUserInfoValidated] = useState<boolean>(false);
    const [formInfo, setFormInfo] = useState<userDetailsFormValues>();
    const [subscriptionAlreadyOnAccount, setSubscriptionAlreadyOnAccount] = useState(false);

    const [user] = useAuthState(firebase_auth);
    const [subscriptionsObject] = useDocumentDataOnce(doc(firebase_firestore, `usermeta/${user?.uid}`));

    const { state } = useMarket(); // dispatch
    const { coupon } = state;
    const location = useLocation();

    const getItemsFromCart = useItemsFromCart();
    const getItemsFromUrl = useItemsFromUrl();
    const { addRequiredItems, clearInvoices } = useAddRequiredItems();
    const { submitUserDetails, submittingUserDetails } = useSubmitUserDetails();
    const { getStripeClientSecret,
        setStripeClientSecret,
        stripeClientSecret,
        oneTimePurchaseItems,
        setOneTimePurchaseItems,
        showType,
        itemsData,
        errorLoadingSubscription } = useStripeClientSecret(coupon, clearInvoices);
    const { processOneTimePurchaseItems } = useProcessOneTimePurchaseItems(setStripeClientSecret, setOneTimePurchaseItems)
    const { createStripeSubscriptionWithPaymentMethod } = useCreateStripeSubscription(processOneTimePurchaseItems);

    const [productIds, setProductIds] = useState<any>(null);

    const options = {
        clientSecret: stripeClientSecret
    }

    const formInformationUpdated = (formInfo: userDetailsFormValues) => {
        const formValidated = Boolean(formInfo && formInfo.phone && formInfo.phoneValidated && formInfo.firstName && formInfo.lastName);
        setUserInfoValidated(formValidated);
        setFormInfo(formInfo);
    }

    useEffect(() => {
        setIsVisible(!errorLoadingSubscription && !loadingSubscription && !pendingSubmit && !submittingUserDetails && !initializingPage);
    }, [errorLoadingSubscription, loadingSubscription, pendingSubmit, submittingUserDetails, initializingPage]);

    useEffect(() => {
        let pids = getItemsFromUrl();
        if (!pids.length) {
            pids = getItemsFromCart();
        }

        setInitializingPage(false);
        setLoadingSubscription(true);
        pids = addRequiredItems(pids); // makes modifications to pids, adding required products
        setProductIds(pids);

        if (subscriptionsObject) {
            processOneTimePurchaseItems(pids).then((res: any) => {
                const { localOtpPurchase, recurringPayments } = res;

                const includesSubscription = recurringPayments.map((price: any) => {
                    return price.priceId;
                }).includes(priceMapper.GCSub)

                for (const tradingHouse in subscriptionsObject.tradingHouses) {
                    const tradingHouseStatus = subscriptionsObject.tradingHouses[tradingHouse].currentStatus.status;
                    const hasGreenChartSubscription = tradingHouse === 'greenchart' && (StatusesMapper.subscriptionStatuses.includes(tradingHouseStatus) || StatusesMapper.trialStatuses.includes(tradingHouseStatus));

                    if (hasGreenChartSubscription && includesSubscription) {
                        // Prevents duplicate items on a subscription from attempted purchase
                        setSubscriptionAlreadyOnAccount(true);
                        return;
                    }
                }

                if ( !localOtpPurchase ) {
                    getStripeClientSecret(recurringPayments).then(() => setLoadingSubscription(false));
                } else {
                    setLoadingSubscription(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriptionsObject])

    function createElements() {
        if (!stripeClientSecret?.length) {
            return <></>
        }

        if (showType === 'card') {
            // @ts-ignore
            return <Elements stripe={stripePromise} options={options}>
                <CheckoutForm
                    type={showType}
                    clientSecret={stripeClientSecret}
                    callback={(paymentMethod: string) => createStripeSubscriptionWithPaymentMethod(paymentMethod, productIds || getItemsFromCart())} // Either built list or get cart
                    setSubmit={setPendingSubmit}
                    submit={pendingSubmit}
                    submitOtherInformation={async () => {
                        if (formInfo) await submitUserDetails(formInfo)
                    }}
                    oneTimePurchaseIncluded={oneTimePurchaseItems && oneTimePurchaseItems.length > 0}
                    setError={setErrorProcessingPayment}
                    informationFilledOut={userInfoValidated}
                />
            </Elements>
        }

        // clearCoupon
        // @ts-ignore
        return <Elements stripe={stripePromise} options={options}>
            <CheckoutForm
                type={showType}
                clientSecret={stripeClientSecret}
                callback={() => {}}
                setSubmit={setPendingSubmit}
                submit={pendingSubmit}
                submitOtherInformation={async () => {
                    if (formInfo) await submitUserDetails(formInfo)
                }}
                oneTimePurchaseIncluded={oneTimePurchaseItems && oneTimePurchaseItems.length > 0}
                setError={setErrorProcessingPayment}
                informationFilledOut={userInfoValidated}
            />
        </Elements>
    }

    function renderProccessingErrorMessage() {
        if (errorProcessingPayment) {
            return (
                <Typography
                    sx={{color: '#df1b41', fontSize: '18px'}}
                >
                    <Box sx={{display: 'flex', direction: 'row'}}>
                        <Box pr={1}>
                            <ErrorIcon fontSize={"medium"}/>
                        </Box>
                        {errorProcessingPayment}
                    </Box>
                </Typography>
            )
        }
    }

    if (subscriptionAlreadyOnAccount) {
        return <>Action blocked, subscription already on account</>
    }

    function loading() {
        let loadingCause = '';

        if (errorLoadingSubscription) {
            loadingCause = 'Error Loading Subscription';
        } else if (loadingSubscription) {
            loadingCause = 'Loading Subscription';
        } else if (pendingSubmit) {
            loadingCause = 'Pending Submit';
        } else if (submittingUserDetails) {
            loadingCause = 'Submitting User Details';
        } else if (initializingPage) {
            loadingCause = 'Initializing Page';
        }

        if (loadingCause) {
            return (
                <>
                    <ProgressCircle />
                </>
            );
        }
    }

    const cameFromLevelSelect = () => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get('prev') === 'member-level';
    };

    if (errorLoadingSubscription) {
        return <>ERROR LOADING SUBSCRIPTION</>
    }

    return <>
        <Box style={{minHeight: '100vh'}}>
            <Header logoOnly={cameFromLevelSelect()}/>
            <Box
                pt={4}
                pl={4}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                <BreadCrumbsNavigation key={3}/>
            </Box>

            {loading()}
            <div style={{ display: isVisible ? 'block' : 'none' }}>
                <Container
                    sx={{ boxShadow: 2, background: '#fefefe' }}
                    style={pendingSubmit ? { display: 'none' } : {}}
                    maxWidth="sm"
                >
                    <Stack
                        spacing={1}
                        py={6}
                        px={3}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                mx: 'auto',
                                justifyContent: 'center'
                            }}>
                            <Typography
                                sx={{
                                    fontWeight: 700,
                                    fontSize: '18px',
                                }}
                            >
                                Order Details
                            </Typography>
                        </Box>

                        {renderProccessingErrorMessage()}
                        <CheckoutItems itemsData={itemsData} oneTimePurchaseItems={oneTimePurchaseItems}/>

                        <UserDetailsForm
                            infoUpdated={formInformationUpdated}
                        />
                        {createElements()}
                    </Stack>

                </Container>
                </div>
        </Box>
        <Footer/>
    </>
}
