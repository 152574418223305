import React, { useEffect, useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';
import { TextField, Button, Typography, Box } from '@mui/material';
import { firebase_auth } from '../../../config/firebaseConfig';
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

interface Props {
    continueUrl?: string;
}

const SetPasswordForm: React.FC<Props> = ({ continueUrl }) => {
    const [email, setEmail] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [success, setSuccess] = useState<boolean>(false);

    const navigate = useNavigate();

    function getParameterByName(name: string) {
        const urlSearchParams = new URLSearchParams(window.location.search);
        return urlSearchParams.get(name);
    }

    useEffect(() => {
        const actionCode = getParameterByName('oobCode');
        verifyPasswordResetCode(firebase_auth, actionCode || '')
            .then((accountEmail) => {
                setEmail(accountEmail);
            })
            .catch(() => {
                navigate('/');
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleResetPassword = () => {
        const actionCode = getParameterByName('oobCode');
        confirmPasswordReset(firebase_auth, actionCode || '', newPassword)
            .then(() => {
                setSuccess(true);
            })
            .catch(() => {
                navigate('/');
            });
    };

    function successMessage() {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center', // Center align items
                }}
            >
                <Typography sx={{ fontSize: '24px', fontWeight: 500 }}>
                    Password Updated
                </Typography>
                <Typography sx={{ fontSize: '18px', color: 'gray', mt: 1 }}>
                    You can now login with your new password
                </Typography>

                {/* Styled Button for Navigation */}
                <Box sx={{ mt: 3, width: '100%', maxWidth: '300px' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        component={RouterLink}
                        to="/login"
                        fullWidth
                    >
                        Go to Login
                    </Button>
                </Box>

                <Box pt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CheckCircleOutlineIcon sx={{ color: 'green', fontSize: '80px' }} />
                </Box>
            </Box>
        );
    }

    return (
        // Outer container that fills the viewport
        <Box
            sx={{
                height: '100vh', // Full viewport height
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center', // Center content vertically and horizontally
                backgroundColor: "#f5f5f5", // Optional background color
                // padding: 2,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    width: '100%',
                    maxWidth: '400px',
                }}
            >
                {success ? (
                    successMessage()
                ) : (
                    <>
                        <Typography sx={{ fontSize: '22px', fontWeight: 500, mb: 2 }}>
                            Set New Password
                        </Typography>
                        <Typography variant="subtitle1" sx={{ mb: 2 }}>
                            Email: {email}
                        </Typography>
                        <TextField
                            fullWidth
                            label="New Password"
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            margin="normal"
                        />
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleResetPassword}
                            sx={{ mt: 2 }}
                        >
                            Set Password
                        </Button>
                        {continueUrl && (
                            <Button
                                fullWidth
                                variant="outlined"
                                color="primary"
                                href={continueUrl}
                                sx={{ mt: 2 }}
                            >
                                Continue
                            </Button>
                        )}
                    </>
                )}
            </Box>
        </Box>
    );
};

export default SetPasswordForm;
