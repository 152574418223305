import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebase_auth, firebase_functions } from "../../config/firebaseConfig";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import ProgressCircle from "../components/ProgressCircle";

const LEARNING_CENTER = "https://learn.greenchart.com";

export default function ExternalRedirect({ children }: any) {
    const { state } = useLocation();
    let { link: externalLink } = state as { link?: string; passedInPath?: string } || {};
    const [token, setToken] = useState<string | null>(null);
    const [searchParams] = useSearchParams();

    const [user, loading] = useAuthState(firebase_auth);
    const [createToken, loadingCreateToken] = useHttpsCallable(
        firebase_functions,
        "createTokenCallable"
    );

    const getLearningCenterId = async () => {
        if (firebase_auth.currentUser) {
            const idToken = await firebase_auth.currentUser.getIdToken(true);
            setToken(idToken);
        }
    };

    useEffect(() => {
        async function generateAndSetToken() {
            // If externalLink starts with the LEARNING_CENTER base URL, use the idToken flow.
            if (externalLink && externalLink.startsWith(LEARNING_CENTER)) {
                await getLearningCenterId();
            } else if (!token && !loadingCreateToken) {
                const tokenResponse = await createToken();
                // @ts-ignore
                setToken(tokenResponse.data);
            }
        }

        if (user && !loading) {
            void generateAndSetToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        const link_qp = searchParams.get("link");

        if (token) {
            if (!state && link_qp) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                externalLink = link_qp;
            }

            // Use "idToken" if externalLink starts with LEARNING_CENTER; otherwise, "authToken".
            const tokenKey =
                externalLink && externalLink.startsWith(LEARNING_CENTER)
                    ? "idToken"
                    : "authToken";
            window.location.href = `${externalLink}?${tokenKey}=${token}`;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    return (
        <>
            <ProgressCircle />
            {children}
        </>
    );
}
