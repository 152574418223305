import React, { useEffect, useState } from "react";
import SignUpForm from "../forms/SignUpForm";
import LoginForm from "../forms/LoginForm";
import ForgottenPasswordForm from "../forms/ForgottenPasswordForm";
import VerifyEmailContent from "./app/VerifyEmailContent";
import useChangeURLWithoutRedirect from "../../../common/hooks/useChangeURLWithoutRedirect";
import MembershipLevelSelect from "./app/MembershipLevelSelect";
import SetPasswordForm from "../forms/SetPasswordForm";

interface ModalContentProps {
    initialState?: 'signUp'
        | 'login'
        | 'emailVerification'
        | 'forgotPassword'
        | 'setPassword'
        | 'verify-email'
        | 'membership-level';
    closeModal?: () => void;
    onStateChange?: (newState: string) => void;
}

const ModalContent: React.FC<ModalContentProps> = ({
                                                       initialState = 'signUp',
                                                       closeModal,
                                                       onStateChange,
                                                   }) => {
    const changeURLWithoutRedirect = useChangeURLWithoutRedirect();

    // Internal content state
    const [contentState, setContentState] = useState(initialState);
    const [signUpError, setSignUpError] = useState<any>(null);
    
    useEffect(() => {
        setContentState(initialState);
    }, [initialState]);

    const switchState = (state: string, url?: string) => {
        setContentState(state as any);
        if (onStateChange) {
            onStateChange(state);
        }
        if (url) {
            changeURLWithoutRedirect(url);
        }
    };

    const renderContent = () => {
        switch (contentState) {
            case "signUp":
                return (
                    <SignUpForm
                        onSwitchToLogin={() => switchState("login", "/login")}
                        setErrorMessage={setSignUpError}
                        errorMessage={signUpError}
                    />
                );
            case "login":
                return (
                    <LoginForm
                        onLoginSuccess={() => closeModal && closeModal()}
                        onSwitchToSignUp={() => switchState("signUp", "/signup")}
                        onSwitchToForgotPassword={() => switchState("forgotPassword", "/forgot-password")}
                    />
                );
            case "forgotPassword":
                return <ForgottenPasswordForm onSwitchToLogin={() => switchState("login", "/login")} />;
            case "verify-email":
                return <VerifyEmailContent/>;
            case "membership-level":
                return <MembershipLevelSelect/>;
            case "setPassword":
                return <SetPasswordForm/>;
            default:
                return null;
        }
    };

    return (<>
        {renderContent()}
    </>);
};

export default ModalContent;
