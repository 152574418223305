import React, {useState, useEffect, useContext} from 'react';
import {Box, Link, Stack, Typography, useMediaQuery, useTheme} from '@mui/material';
import {applyActionCode, getAuth, sendEmailVerification, signOut} from "firebase/auth";
import {useNavigate, useSearchParams} from "react-router-dom";
import useUserDetails from "../../../../common/hooks/useUserDetails";
import ProgressCircle from "../../../../common/components/ProgressCircle";
import {useAuthState} from "react-firebase-hooks/auth";
import {firebase_auth} from "../../../../config/firebaseConfig";
import useSetPhase from "../../../../common/hooks/registerationPhases/useSetPhase";
import {SnackbarHelperContext} from "../../../../common/contexts/SnackbarHelperContext";
import logo from "../../../../assets/stacked_logo.png";

const VerifyEmailContent = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const navigate = useNavigate();
    const {userDetails: usermeta} = useUserDetails();

    const [searchParams] = useSearchParams();
    const [user, loadingUser] = useAuthState(firebase_auth);
    const setPhase = useSetPhase();
    const {setSnackbarSettings} = useContext(SnackbarHelperContext);

    // state to inform the ui if the email has been verified
    const [emailVerified, setEmailVerified] = useState(false);
    // locks to prevent multiple attempts to apply the action code
    const [oobConsumed, setOobConsumed] = useState(false);
    const [applyingOob, setApplyingOob] = useState(false);

    const handleResend = async () => {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
            try {
                await sendEmailVerification(user);
                setSnackbarSettings({
                    severity: 'success',
                    message: 'An email was sent to your email address. Please check your inbox and spam folder to verify your email address.'
                })
            } catch (error: any) {
                setSnackbarSettings({severity: 'error', message: `Failed to send verification email: ${error.message}`})
            }
        } else {
            setTimeout(() => {
                navigate("/login", {replace: true});
            }, 5000);
        }
    };

    const handleLogout = async () => {
        const auth = getAuth();
        try {
            await signOut(auth);
            navigate("/signup", {replace: true});
        } catch (error: any) {
            setSnackbarSettings({severity: 'error', message: `Failed to sign out: ${error.message}`})
        }
    };

    // When they come back to this window it reloads everytime to check if the email has been verified and redirect
    useEffect(() => {
        const handleFocus = () => {
            window.location.reload();
        };

        window.addEventListener('focus', handleFocus);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('focus', handleFocus);
        };
    }, []);

    // When they come to this page with an OOB code, we apply the action code here
    useEffect(() => {
        const verifyEmail = async () => {
            const params = new URLSearchParams(window.location.search);
            const oobCode = params.get("oobCode");

            if (!oobCode || oobConsumed || applyingOob) {
                return;
            }

            try {
                const auth = getAuth();

                setApplyingOob(true);
                await applyActionCode(auth, oobCode);
                setApplyingOob(false);

                handleSuccessfullyVerified();
            } catch (error: any) {
                if (error.code === "auth/expired-action-code") {
                    setSnackbarSettings({
                        severity: 'error',
                        message: "The verification link has expired. Please request a new verification email."
                    })
                } else if (error.code === "auth/invalid-action-code") {
                    setSnackbarSettings({
                        severity: 'error',
                        message: "The verification link is invalid. It might have already been used."
                    })
                } else {
                    setSnackbarSettings({
                        severity: 'error',
                        message: "An unexpected error occurred during email verification."
                    })
                }

                if (!user) {
                    setTimeout(() => {
                        navigate('/login', {replace: true});
                    }, 3000);
                    return;
                }
            } finally {
                setApplyingOob(false);
                // Remove the oobCode so this doesn't run multiple times
                params.delete("oobCode");
                window.history.replaceState({}, "", `${window.location.pathname}?${params}`);
            }
        };

        // Sets them to next phase and location after email is verified
        function handleSuccessfullyVerified() {
            setEmailVerified(true);
            setOobConsumed(true);

            setTimeout(async () => {
                setSnackbarSettings({severity: 'success', message: `Email verified!`});
                if (user) {
                    await setPhase('membership-level');
                } else {
                    navigate('/login', {replace: true});
                    return;
                }
            }, 3000);
        }

        void verifyEmail();
    }, [applyingOob, navigate, oobConsumed, setPhase, setSnackbarSettings, user]);

    return (
        <Box
            sx={{
                backgroundColor: "#030a23",
                minHeight: "100vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Box
                sx={{
                    width: isMobile ? "90%" : "600px",
                    backgroundColor: "white",
                    borderRadius: "8px",
                    boxShadow: "none",
                    overflow: "visible",
                    padding: "16px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "16px",
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            width: "200px",
                            cursor: "pointer",
                        }}
                        src={logo}
                        onClick={() => window.open("https://www.greenchart.com", "_blank")}
                    />
                </Box>

                { loadingUser ? (
                    <ProgressCircle/>
                ) : searchParams.get("oobCode") !== null && !oobConsumed ? (
                    <Stack
                        spacing={2}
                        sx={{
                            minHeight: "350px",
                            minWidth: "360px",
                            alignItems: "center",
                            padding: "24px",
                            backgroundColor: theme.palette.background.paper,
                            borderRadius: theme.shape.borderRadius,
                            margin: "auto",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "18px",
                                fontWeight: 500,
                                textAlign: "center",
                                margin: "16px 0",
                                color: "text.primary",
                            }}
                        >
                            Verifying email address, please wait...
                        </Typography>
                        <ProgressCircle/>
                    </Stack>
                ) : emailVerified ? (
                    <Stack
                        spacing={2}
                        sx={{
                            minHeight: "350px",
                            minWidth: "360px",
                            alignItems: "center",
                            padding: "24px",
                            backgroundColor: theme.palette.background.paper,
                            borderRadius: theme.shape.borderRadius,
                            margin: "auto",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "18px",
                                fontWeight: 500,
                                textAlign: "center",
                                margin: "16px 0",
                                color: "text.primary",
                            }}
                        >
                            Your email has been verified and will redirect shortly!
                        </Typography>
                        <ProgressCircle/>
                    </Stack>
                ) : (
                    <Stack
                        spacing={3}
                        sx={{
                            minWidth: "360px",
                            minHeight: "200px",
                            justifyContent: "center",
                        }}
                    >
                        <Box
                            sx={{
                                paddingY: "24px",
                                maxWidth: 400,
                                width: "100%",
                                textAlign: "center",
                                backgroundColor: theme.palette.background.paper,
                                borderRadius: theme.shape.borderRadius,
                                margin: "auto",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "22px",
                                    fontWeight: 500,
                                    marginBottom: theme.spacing(2),
                                }}
                            >
                                Verify Your Email
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: "18px",
                                    fontWeight: 400,
                                    color: "gray",
                                    marginBottom: theme.spacing(2),
                                }}
                            >
                                We've sent a verification email to:
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: "18px",
                                    fontWeight: 500,
                                    marginBottom: theme.spacing(3),
                                    wordBreak: "break-word",
                                }}
                            >
                                {usermeta?.email}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: "18px",
                                    fontWeight: 400,
                                    color: "gray",
                                    marginBottom: theme.spacing(2),
                                }}
                            >
                                Click the link in your email to verify your account. If you can't find the email, check your spam folder or {" "}
                                <Link
                                    component="button"
                                    onClick={handleResend}
                                    sx={{
                                        textDecoration: "none",
                                        fontSize: "18px",
                                        fontWeight: 500,
                                        color: theme.palette.primary.main,
                                        cursor: "pointer",
                                    }}
                                >
                                    click here to resend.
                                </Link>
                            </Typography>
                            <Typography
                                onClick={handleLogout}
                                sx={{
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    marginTop: theme.spacing(3),
                                    color: theme.palette.primary.main,
                                    textAlign: "center",
                                    cursor: "pointer",
                                    '&:hover': {
                                        color: theme.palette.primary.dark,
                                    },
                                }}
                            >
                                If you need to start over, click here
                            </Typography>


                        </Box>
                    </Stack>
                )}
            </Box>
        </Box>
    );
};

export default VerifyEmailContent;
