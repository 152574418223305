import { useCallback } from "react";
import { deleteField, doc, updateDoc } from "firebase/firestore";
import { firebase_auth, firebase_firestore } from "../../../config/firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";

const useSetPhase = () => {
    const [user, loadingUser] = useAuthState(firebase_auth);

    const setPhase = useCallback(async (phase?: string) => {
        if (loadingUser) {
            return;
        }

        if (!user) {
            console.error('No user found.');
            return;
        }

        try {
            const userDocRef = doc(firebase_firestore, `usermeta/${user.uid}`);
            await updateDoc(userDocRef, { registerPhase: phase ? phase : deleteField() });
        } catch (error) {
            console.error("Error deleting registerPhase:", error);
        }
    }, [user, loadingUser]);

    return setPhase;
};

export default useSetPhase;
