// hooks/useConditionalNavigation.ts
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import { GeneralContext } from "../../contexts/GeneralContext";
import useUserDetails from "../../hooks/useUserDetails";
import ProgressCircle from "../../components/ProgressCircle";
import React from "react";

interface ConditionalNavigationResult {
    component: React.ReactElement | null;
}

const useConditionalNavigation = (): ConditionalNavigationResult => {
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const { preserveUrlParams, checkAccountStatuses, setRepId } = useContext(GeneralContext);
    const { user, loadingUser, loadingUserDetails, userDetails } = useUserDetails();
    const [hasPaidSS, setHasPaidSS] = useState<boolean | null>(null)

    useEffect(() => {
        if (user && !loadingUser && userDetails) {
            const accountStatuses = checkAccountStatuses(userDetails);
            if (accountStatuses.hasPaidSS) {
                setHasPaidSS(true);
            } else {
                setHasPaidSS(false);
            }
        }
    }, [checkAccountStatuses, loadingUser, user, userDetails]);

    useEffect(() => {
        if (location) {
            const params = new URLSearchParams(location.search);
            const repId = params.get('repId');
            if (repId) {
                setRepId(repId);
            }
        }
    }, [location, setRepId])

    if (loadingUser || loadingUserDetails || hasPaidSS === null) {
        // Return the loading component
        return { component: <ProgressCircle /> };
    }

    if (userDetails) {
        if (location.pathname !== '/signup' && userDetails.registerPhase) {
            return { component: null };
        }
    }

    if (user && !loadingUser && userDetails && location.pathname !== '/signup') {
        if (searchParams.get('display')) {
            return { component: <Navigate to={`/dashboard/marketplace${preserveUrlParams(searchParams)}`} replace /> };
        } else if (hasPaidSS) {
            return { component: <Navigate to={`/dashboard/strategy-suite`} /> };
        } else {
            return { component: <Navigate to={`/dashboard/strategy-suite${preserveUrlParams(searchParams)}`} replace /> };
        }
    }

    return { component: null };
};

export default useConditionalNavigation;
